import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IUsuario,
  ICreateUsuario,
  IUpdateUsuario,
  ICliente,
  IQueryMongo,
  IListado,
  TipoUsuario,
  IProvincia,
  IDepartamento,
  ILocalidad,
} from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { UsuariosService } from '../usuarios.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-crear-editar-usuarios',
  templateUrl: './crear-editar-usuarios.component.html',
  styleUrls: ['./crear-editar-usuarios.component.scss'],
})
export class CrearEditarUsuariosComponent implements OnInit {
  public loading = false;
  public form?: UntypedFormGroup;
  public title?: string;
  public hide = true;

  public clientes: ICliente[] = [];
  public provincias: IProvincia[] = [];
  public departamentos: IDepartamento[] = [];
  public localidades: ILocalidad[] = [];
  public roles: TipoUsuario[] = ['Admin', 'Lector', 'Relevador'];

  get formArrayPermisos() {
    return this.form?.get('permisos') as UntypedFormArray;
  }

  // Listado Continuo
  public clientes$?: Subscription;
  public provincias$?: Subscription;
  public departamentos$?: Subscription;
  public localidades$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUsuario,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarUsuariosComponent>,
    private service: UsuariosService,
    private helper: HelperService,
    private listadosService: ListadosService
  ) {}

  getUsernameErrorMessage() {
    if (this.form?.get('username')?.hasError('required')) {
      return 'Debe Ingresar un Email';
    }
    return this.form?.get('username')?.hasError('email')
      ? 'Email inválido'
      : '';
  }

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Usuario' : 'Crear Usuario';

    const formPermisos: UntypedFormGroup[] = [];

    if (this.data?.permisos?.length) {
      for (const permiso of this.data?.permisos) {
        formPermisos.push(
          this.fb.group({
            tipo: [permiso.tipo, Validators.required],
            global: [permiso.global],
            // idsZona: [permiso.idsZona],
            // idsRegion: [permiso.idsRegion],
            idsProvincia: [permiso.idsProvincia],
            idsDepartamento: [permiso.idsDepartamento],
            idsLocalidad: [permiso.idsLocalidad],
          })
        );
      }
    }

    this.form = this.fb.group({
      username: [this.data?.username, [Validators.required, Validators.email]],
      password: ['', !this.data ? Validators.required : null],
      idCliente: [this.data?.idCliente, Validators.required],
      permisos: this.fb.array(formPermisos),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getCreateData() {
    const data: ICreateUsuario = this.form?.value;
    return data;
  }

  private getUpdateData() {
    const data: IUpdateUsuario = this.form?.value;
    // Password
    delete data.password;
    const password = this.form?.get('password')?.value;
    if (password) {
      data.password = password;
    }
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      if (this.data?._id) {
        const data = this.getUpdateData();
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        const data = this.getCreateData();
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  //

  public agregarPermiso() {
    this.formArrayPermisos.push(
      this.fb.group({
        tipo: [null, Validators.required],
        global: [null],
        // idsZona: [null],
        // idsRegion: [null],
        idsProvincia: [null],
        idsDepartamento: [null],
        idsLocalidad: [null],
      })
    );
  }

  public eliminarPermiso(i: number) {
    this.formArrayPermisos.removeAt(i);
  }

  // Listados

  private async listarClientes(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.clientes$?.unsubscribe();
    this.clientes$ = this.listadosService
      .subscribe<IListado<ICliente>>('clientes', query)
      .subscribe((data) => {
        this.clientes = data.datos;
        console.log(`listado de clientes`, data);
      });
    await this.listadosService.getLastValue('clientes', query);
  }

  private async listarProvincias(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.provincias$?.unsubscribe();
    this.provincias$ = this.listadosService
      .subscribe<IListado<IProvincia>>('provincias', query)
      .subscribe((data) => {
        this.provincias = data.datos;
        console.log(`listado de provincias`, data);
      });
    await this.listadosService.getLastValue('provincias', query);
  }

  private async listarDepartamentos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.departamentos$?.unsubscribe();
    this.departamentos$ = this.listadosService
      .subscribe<IListado<IDepartamento>>('departamentos', query)
      .subscribe((data) => {
        this.departamentos = data.datos;
        console.log(`listado de departamentos`, data);
      });
    await this.listadosService.getLastValue('departamentos', query);
  }

  private async listarLocalidades(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.localidades$?.unsubscribe();
    this.localidades$ = this.listadosService
      .subscribe<IListado<ILocalidad>>('localidads', query)
      .subscribe((data) => {
        this.localidades = data.datos;
        console.log(`listado de localidads`, data);
      });
    await this.listadosService.getLastValue('localidads', query);
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await Promise.all([
      this.listarClientes(),
      this.listarProvincias(),
      this.listarDepartamentos(),
      this.listarLocalidades(),
    ]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.clientes$?.unsubscribe();
    this.provincias$?.unsubscribe();
    this.departamentos$?.unsubscribe();
    this.localidades$?.unsubscribe();
  }
}
