<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[estado, cliente]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" (click)="crear()">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna Nombre de usuario -->
    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Username
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.username }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Cliente -->
    <ng-container matColumnDef="cliente">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Cliente
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.cliente?.nombre }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Activo -->
    <ng-container matColumnDef="activo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Activo
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.activo" [style.color]="'green'">check</mat-icon>
        <mat-icon *ngIf="!row.activo" [style.color]="'red'">clear</mat-icon>
      </mat-cell>
    </ng-container>

    <!-- Columna Permisos -->
    <ng-container matColumnDef="permisos">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Permisos
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <ng-container *ngIf="row.permisos?.length">
            <ng-container *ngFor="let permiso of row.permisos">
              <div>
                <p style="margin: 0; padding: 0.2em">
                  <strong>
                    {{ permiso.tipo }}
                  </strong>
                </p>
                <ng-container *ngIf="permiso.global">
                  <p style="margin: 0; color: #673ab7">
                    <span style="padding: 0.2em">Global</span>
                  </p>
                </ng-container>
                <ng-container *ngIf="!permiso.global">
                  <!-- Regiones -->
                  <p
                    *ngIf="permiso.regiones?.length"
                    style="margin: 0; color: #673ab7"
                    matTooltip="Region"
                  >
                    <ng-container *ngFor="let e of permiso.regiones">
                      <span style="padding: 0.2em">{{ e.nombre }}</span>
                    </ng-container>
                  </p>
                  <!-- Zonas -->
                  <p
                    *ngIf="permiso.zonas?.length"
                    style="margin: 0; color: #673ab7"
                    matTooltip="Zona"
                  >
                    <ng-container *ngFor="let e of permiso.zonas">
                      <span style="padding: 0.2em"> {{ e.nombre }} </span>
                    </ng-container>
                  </p>
                  <!-- Provincias -->
                  <p
                    *ngIf="permiso.provincias?.length"
                    style="margin: 0; color: #673ab7"
                    matTooltip="Provincia"
                  >
                    <ng-container *ngFor="let e of permiso.provincias">
                      <span style="padding: 0.2em"> {{ e.nombre }} </span>
                    </ng-container>
                  </p>
                  <!-- Departamentos -->
                  <p
                    *ngIf="permiso.departamentos?.length"
                    style="margin: 0; color: #673ab7"
                    matTooltip="Departamento"
                  >
                    <ng-container *ngFor="let e of permiso.departamentos">
                      <span style="padding: 0.2em"> {{ e.nombre }} </span>
                    </ng-container>
                  </p>
                  <!-- Localidades -->
                  <p
                    *ngIf="permiso.localidades.length"
                    style="margin: 0; color: #673ab7"
                    matTooltip="Localidad"
                  >
                    <ng-container *ngFor="let e of permiso.localidades">
                      <span style="padding: 0.2em"> {{ e.nombre }} </span>
                    </ng-container>
                  </p>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        style="overflow: visible"
        fxLayoutGap="5px"
      >
        <button
          mat-mini-fab
          matTooltip="Editar"
          color="accent"
          (click)="editar(row)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
  <mat-paginator-goto
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator-goto>
</div>
