import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { TipoUsuario } from 'modelos/src';
import { LoginService } from '../../modulos/login/login.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class RolesGlobalesGuard  {
  constructor(private router: Router, private helper: HelperService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const rolesPermitidos = route.data.roles as TipoUsuario[];
    const rolesUsuario =
      LoginService.getUsuario().permisos.map((p) => p.tipo) || [];
    if (
      !rolesPermitidos?.length ||
      rolesPermitidos.some((rol) => rolesUsuario.includes(rol))
    ) {
      return true;
    } else {
      this.helper.notifWarn('Permiso de rol denegado');
      return this.router.parseUrl('/main');
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot) {
    const rolesPermitidos = route.data.roles as TipoUsuario[];
    const rolesUsuario =
      LoginService.getUsuario().permisos.map((p) => p.tipo) || [];
    if (
      !rolesPermitidos?.length ||
      rolesPermitidos.some((rol) => rolesUsuario.includes(rol))
    ) {
      return true;
    } else {
      this.helper.notifWarn('Permiso de rol denegado');
      return this.router.parseUrl('/main');
    }
  }
}
