<div
  fxLayoutAlign="center center"
  fxFill
  style="
    background-image: url('assets/images/login.webp');
    background-size: cover;
    background-position: center center;
  "
>
  <mat-card style="width: 400px; max-width: 85%">
    <mat-card-header>
      <mat-card-subtitle> AGROINSUMOS - ADMIN </mat-card-subtitle>
      <mat-card-title>INICIO DE SESIÓN</mat-card-title>
    </mat-card-header>
    <!-- Form -->
    <mat-card-content style="margin: 2em 0">
      <form id="loginForm" [formGroup]="form" (ngSubmit)="onSubmit()">
        <!-- Usuario  -->
        <mat-form-field appearance="fill" fxFill>
          <mat-label>Usuario</mat-label>
          <input matInput formControlName="username" />
        </mat-form-field>

        <!-- Contraseña -->
        <mat-form-field appearance="fill" fxFill>
          <mat-label>Password</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
        </mat-form-field>
      </form>
    </mat-card-content>

    <mat-divider></mat-divider>

    <!-- Botones -->
    <mat-card-actions>
      <button
        mat-raised-button
        type="submit"
        form="loginForm"
        color="accent"
        style="width: 100%"
        [disabled]="!form.pristine && (!form.valid || loading)"
      >
        INGRESAR
      </button>
    </mat-card-actions>

    <!-- Progress Bar  -->
    <mat-card-footer>
      <mat-progress-bar
        [mode]="loading ? 'indeterminate' : 'determinate'"
      ></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
