<ng-container cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h2 mat-dialog-title class="modal-header" cdkDragHandle>{{ title }}</h2>
</ng-container>

<mat-dialog-content style="min-height: 50px;">
  {{ message }}
</mat-dialog-content>

<br>

<mat-dialog-actions fxLayoutAlign="end center">
  <button mat-raised-button color="primary" (click)="matDialogRef.close(true)">
    {{ btnOkText }}
  </button>
  <button mat-raised-button (click)="matDialogRef.close(false)">
    {{ btnCancelText }}
  </button>
</mat-dialog-actions>