import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { IFilter, IQueryMongo } from 'modelos/src';
import { fromEvent, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { ListadosService } from '../listados.service';

export interface IFiltroTabla {
  // Campos comunes
  filter: IFilter;
  label: string; // Etiqueta para mostrar
  tipo: 'select' | 'input' | 'dateRange' | 'ngselect-async'; // Tipo de campo a mostrar
  // Para select
  elementos?: any[]; // Arreglo de elementos (para el select)
  selectValue?: string; // Valor a seleccionar de los elementos en el select (para select)
  selectLabel?: string; // Valor a mostrar de los elementos en el select (para select)
  // Para dateRange
  desde?: string;
  hasta?: string;
  // Para ngselect-async
  asyncFunction?: string;
  searchOn?: string[];
  populate?: string;
}

@Component({
  selector: 'app-filtro-tabla',
  templateUrl: './filtro-tabla.component.html',
  styleUrls: ['./filtro-tabla.component.scss'],
})
export class FiltroTablaComponent implements OnInit {
  @ViewChild('inputFiltro', { static: false }) inputFiltro:
    | ElementRef
    | undefined;
  @Input() datos: IFiltroTabla[] = [];
  @Input() search: IFilter | undefined;
  @Output() onFilterChange: EventEmitter<IFilter[]> = new EventEmitter<
    IFilter[]
  >();

  public asyncData$?: Observable<any[]>[] = [];
  public asyncInput$?: Subject<string>[] = [];

  public filtroActivo: boolean = false;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private listadosService: ListadosService,
    private breakpointObserver: BreakpointObserver
  ) {}

  private crearQuery(): IFilter[] {
    const filters: IFilter[] = [];
    this.datos.forEach((dato) => {
      if (
        dato.tipo === 'input' ||
        dato.tipo === 'select' ||
        dato.tipo === 'ngselect-async'
      ) {
        if (dato.filter.value && dato.filter.field) {
          filters.push(dato.filter);
        }
      } else if (dato.tipo === 'dateRange') {
        if (dato.filter.field && (dato.desde || dato.hasta)) {
          const filter: IFilter = {
            field: dato.filter.field,
            type: 'object',
            value: {},
          };
          if (dato.desde) {
            const date = new Date(dato.desde);
            date.setHours(0, 0, 0, 0);
            filter.value.$gte = date.toISOString();
          }
          if (dato.hasta) {
            const date = new Date(dato.hasta);
            date.setHours(23, 59, 59, 999);
            filter.value.$lte = date.toISOString();
          }
          filters.push(filter);
        }
      }
    });
    if (this.search?.value) {
      filters.push(this.search);
    }
    return filters;
  }

  public updatefiltroActivo() {
    const selected = this.datos.find(
      (dato) => dato.filter.value || dato.desde || dato.hasta
    );
    this.filtroActivo = selected ? true : false;
  }
  public async cambioFiltro() {
    this.updatefiltroActivo();
    const query = this.crearQuery();
    this.onFilterChange.emit(query);
  }

  public async limpiarFiltros() {
    this.datos.forEach((dato) => {
      dato.filter.value = undefined;
      dato.desde = undefined;
      dato.hasta = undefined;
    });
    this.cambioFiltro();
  }

  private initFilterChange(): void {
    if (this.inputFiltro) {
      fromEvent(this.inputFiltro?.nativeElement, 'keyup')
        .pipe(
          map((e: any) => e.target.value),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe((text: string) => {
          this.cambioFiltro();
        });
    }
  }

  private crearFiltro(search: string, i: number): IQueryMongo {
    const filtro: IFilter[] = [];
    if (search) {
      filtro.push({
        field: this.datos[i].searchOn!,
        type: 'regex',
        value: search,
      });
    }
    const query: IQueryMongo = {
      filter: JSON.stringify(filtro),
      limit: 10,
      populate: this.datos[i].populate,
    };
    return query;
  }

  private onSearch(i: number) {
    this.asyncData$![i] = of([]);
    this.asyncInput$![i] = new Subject<string>();
    this.asyncInput$![i].pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => {
        return (this.listadosService as any)[this.datos[i].asyncFunction!](
          this.crearFiltro(term, i)
        );
      })
    ).subscribe((data: any) => {
      // this.data = data;
      this.asyncData$![i] = of(data);
    });
  }

  ngOnInit(): void {
    this.datos.forEach((e, index) => {
      if (e.tipo === 'ngselect-async') {
        this.onSearch(index);
      }
    });
  }

  ngAfterViewInit() {
    this.initFilterChange();
  }
}
