import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICreateFamiliaQuimica, IFamiliaQuimica } from 'modelos/src';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { FamiliaQuimicasService } from '../familiaQuimicas.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-crear-editar-familia-quimicas',
  templateUrl: './crear-editar-familia-quimicas.component.html',
  styleUrls: ['./crear-editar-familia-quimicas.component.scss'],
})
export class CrearEditarFamiliaQuimicasComponent implements OnInit {
  public loading = false;
  public form?: UntypedFormGroup;
  public title?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IFamiliaQuimica,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarFamiliaQuimicasComponent>,
    private helper: HelperService,
    private service: FamiliaQuimicasService
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Familia Química'
      : 'Crear Familia Química';
    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateFamiliaQuimica = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      let result: IFamiliaQuimica;
      if (this.data?._id) {
        result = await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        result = await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(result);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  async ngOnInit(): Promise<void> {
    this.createForm();
  }
}
