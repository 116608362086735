import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { SubsegmentosRoutingModule } from './subsegmentos.routing.module';
import { ListadoSubsegmentosComponent } from './listado-subsegmentos/listado-subsegmentos.component';
import { CrearEditarSubsegmentosComponent } from './crear-editar-subsegmentos/crear-editar-subsegmentos.component';

@NgModule({
  declarations: [
    ListadoSubsegmentosComponent,
    CrearEditarSubsegmentosComponent
  ],
  imports: [CommonModule, AuxiliaresModule, SubsegmentosRoutingModule],
})
export class SubsegmentosModule {}
