import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IFilter, IListado, IPrincipioActivo, IQueryMongo } from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarPrincipioActivosComponent } from '../crear-editar-principio-activos/crear-editar-principio-activos.component';
import { PrincipioActivosService } from '../principioActivos.service';

@Component({
  selector: 'app-listado-principio-activos',
  templateUrl: './listado-principio-activos.component.html',
  styleUrls: ['./listado-principio-activos.component.scss'],
})
export class ListadoPrincipioActivosComponent implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<IPrincipioActivo>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'nombre',
    'nombreCorto',
    'idFamiliaQuimica',
    'acciones',
  ];
  public nombreColumnas: string[] = [
    'Principio Activo',
    'Nombre Corto',
    'Familia Química',
    'Acciones',
  ];
  public saveColumnas = 'precios-admin-principio-activos';
  // Filtros Tabla
  public search: IFilter = {
    field: ['nombre', 'nombreCorto'],
    type: 'regex',
    value: '',
  };

  private populate = {
    path: 'familiaQuimica',
    select: 'nombre',
  };
  public queryParams: IQueryMongo = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'nombre',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: PrincipioActivosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter[]) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPrincipioActivosComponent, config);
  }

  public async editar(data: IPrincipioActivo) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarPrincipioActivosComponent, config);
  }

  public async eliminar(dato: IPrincipioActivo): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el principio activo ${dato.nombre}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IPrincipioActivo>>(
        'principioActivos',
        this.queryParams
      )
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de principioActivos`, data);
      });
    await this.listadosService.getLastValue(
      'principioActivos',
      this.queryParams
    );
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.listar()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
