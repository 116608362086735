<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <form
    *ngIf="form"
    id="formProducto"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
  >
    <!-- Info Basica -->
    <div
      fxFlex.gt-md="50%"
      fxFlex.lt-lg="100%"
      style="padding: 1em"
      [formGroup]="form"
    >
      <h2>Información Básica</h2>
      <!-- Marca Comercial / Nombre Producto -->
      <mat-form-field style="width: 100%">
        <mat-label>Marca comercial</mat-label>
        <input
          matInput
          placeholder="Nombre del producto"
          formControlName="nombre"
          required
        />
      </mat-form-field>

      <!-- Empresa -->
      <div class="row-space-between" style="gap: 5px">
        <mat-form-field style="width: 100%" class="no-hint">
          <mat-label>Empresa</mat-label>
          <mat-select formControlName="idEmpresa">
            <mat-option *ngFor="let dato of empresas" [value]="dato._id">{{
              dato.nombre
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          class="prefix-btn"
          type="button"
          color="primary"
          mat-mini-fab
          tabindex="-1"
          matTooltip="Crear"
          (click)="crearEmpresa()"
        >
          +
        </button>
      </div>
      <br />
      <!-- Segmento -->
      <div class="row-space-between" style="gap: 5px">
        <mat-form-field style="width: 100%" class="no-hint">
          <mat-label>Segmento</mat-label>
          <mat-select
            formControlName="idSegmento"
            (selectionChange)="cambioSegmento()"
          >
            <mat-option *ngFor="let dato of segmentos" [value]="dato._id">{{
              dato.nombre
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          class="prefix-btn"
          type="button"
          color="primary"
          mat-mini-fab
          tabindex="-1"
          matTooltip="Crear"
          (click)="crearSegmento()"
        >
          +
        </button>
      </div>
      <br />
      <!-- Subsegmentos -->
      <div class="row-space-between" style="gap: 5px">
        <mat-form-field style="width: 100%" class="no-hint">
          <mat-label>Subsegmentos</mat-label>
          <mat-select formControlName="idsSubsegmentos" multiple>
            <ng-container *ngFor="let dato of subsegmentos">
              <mat-option
                *ngIf="form.get('idSegmento')?.value === dato.idSegmento"
                [value]="dato._id"
                >{{ dato.nombre }}</mat-option
              >
            </ng-container>
          </mat-select>
        </mat-form-field>
        <button
          class="prefix-btn"
          type="button"
          color="primary"
          mat-mini-fab
          tabindex="-1"
          matTooltip="Crear"
          (click)="crearSubsegmento()"
        >
          +
        </button>
      </div>
      <br />
      <!-- Unidad -->
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between center"
        fxLayoutGap.gt-sm="15px"
      >
        <mat-form-field fxFlex="30%">
          <mat-label>Unidad</mat-label>
          <mat-select name="unidad" formControlName="unidad">
            <mat-option *ngFor="let dato of unidades" [value]="dato">
              {{ dato }}
            </mat-option>
          </mat-select>
          <button
            *ngIf="form.get('unidad')?.value"
            matSuffix
            mat-icon-button
            type="button"
            (click)="
              $event.stopPropagation(); form.patchValue({ unidad: null })
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field fxFlex="30%">
          <mat-label>Formulación</mat-label>
          <mat-select
            formControlName="formulacion"
            (selectionChange)="cambioFormulacion()"
          >
            <mat-option *ngFor="let dato of formulaciones" [value]="dato">
              {{ dato }}
            </mat-option>
          </mat-select>
          <button
            *ngIf="form.get('formulacion')?.value"
            matSuffix
            mat-icon-button
            type="button"
            (click)="
              $event.stopPropagation(); form.patchValue({ formulacion: null })
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field fxFlex="30%">
          <mat-label>Clase Toxicológica</mat-label>
          <mat-select
            formControlName="claseTox"
            (selectionChange)="cambioClaseTox()"
          >
            <mat-option *ngFor="let dato of clasesTox" [value]="dato">
              {{ dato }}
            </mat-option>
          </mat-select>
          <button
            *ngIf="form.get('claseTox')?.value"
            matSuffix
            mat-icon-button
            type="button"
            (click)="
              $event.stopPropagation(); form.patchValue({ claseTox: null })
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- Dosis Media / SKU / Nro. Registro -->
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="space-between center"
        fxLayoutGap.gt-sm="15px"
      >
        <mat-form-field fxFlex="30%">
          <mat-label>Dosis Media</mat-label>
          <input matInput formControlName="dosisMedia" />
          <span matSuffix>{{ form.get("unidad")?.value }}/Ha</span>
        </mat-form-field>

        <mat-form-field fxFlex="30%">
          <mat-label>SKU</mat-label>
          <input matInput placeholder="" formControlName="sku" />
        </mat-form-field>

        <mat-form-field fxFlex="30%">
          <mat-label>Nro. Registro</mat-label>
          <input matInput placeholder="" formControlName="numeroRegistro" />
        </mat-form-field>
      </div>
    </div>

    <!-- Composicion -->
    <div fxFlex.gt-md="50%" fxFlex.lt-lg="100%" style="padding: 1em">
      <h2>Composición</h2>

      <!-- Cantidad de compuestos -->
      <mat-form-field class="full-width">
        <mat-label>Cantidad de compuestos</mat-label>
        <input
          name="cantidadCompuestos"
          matInput
          type="number"
          [(ngModel)]="cantidadCompuestos"
          (change)="cambioCantidadCompuestos()"
          readonly
        />
        <div matSuffix fxLayoutGap="5px">
          <button
            type="button"
            color="warn"
            class="prefix-btn"
            mat-mini-fab
            tabindex="-1"
            [disabled]="cantidadCompuestos <= 1"
            (click)="
              cantidadCompuestos = cantidadCompuestos - 1;
              cambioCantidadCompuestos()
            "
          >
            -
          </button>
          <button
            type="button"
            color="accent"
            class="prefix-btn"
            mat-mini-fab
            tabindex="-1"
            [disabled]="cantidadCompuestos === 24"
            (click)="
              cantidadCompuestos = cantidadCompuestos + 1;
              cambioCantidadCompuestos()
            "
          >
            +
          </button>
        </div>
      </mat-form-field>

      <!-- Principios Activos -->
      <div fxLayout="column" fxLayoutGap="10px">
        <ng-container *ngFor="let composicion of composiciones; let i = index">
          <div
            style="background-color: rgba(0, 0, 0, 0.05); padding: 0.5em"
            [ngClass]="composicionValida(composicion) ? 'valid' : 'not-valid'"
          >
            <!-- Principio Activo -->

            <div class="row-space-between" style="gap: 5px">
              <div style="width: 100%">
                <ng-select
                  [name]="'idPrincipioActivo' + i"
                  style="width: 100%"
                  placeholder="Principio Activo"
                  [items]="principioActivos"
                  bindLabel="nombre"
                  bindValue="_id"
                  [(ngModel)]="composicion.idPrincipioActivo"
                  [searchFn]="searchPrincipioActivo"
                  (change)="cambioPrincipioActivo($event, i)"
                >
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-item$="item$"
                    let-index="index"
                  >
                    <div fxLayout="column">
                      <div style="padding-bottom: 5px">
                        <span>{{ item.nombre }} </span>
                        <span style="font-size: 0.7em">
                          ({{ item.familiaQuimica?.nombre }})</span
                        >
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <button
                class="prefix-btn"
                type="button"
                color="primary"
                mat-mini-fab
                tabindex="-1"
                matTooltip="Crear"
                (click)="crearPrincipioActivo(i)"
              >
                +
              </button>
            </div>

            <div class="row-space-between" style="gap: 5px">
              <mat-form-field class="full-width">
                <mat-label>Concentración</mat-label>
                <input
                  matInput
                  type="number"
                  min="0"
                  [name]="'concentracion' + i"
                  [(ngModel)]="composicion.concentracion"
                />
                <span matSuffix>%</span>
              </mat-form-field>

              <mat-checkbox
                [name]="'principal' + i"
                [(ngModel)]="composicion.principal"
              >
                Principal
              </mat-checkbox>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- Compuesto -->
      <div
        style="margin: 1em; padding: 0.5em"
        [ngClass]="composicionesValidas() ? 'valid' : 'not-valid'"
        *ngIf="cantidadCompuestos > 1"
      >
        <h2>Compuesto</h2>
        <span>{{ getCompuesto() }}</span>
      </div>
    </div>
  </form>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="padding: 1em">
    <button
      mat-raised-button
      type="submit"
      form="formProducto"
      color="accent"
      fxFlex
      [disabled]="botonEnviarDisabled()"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="volver()">
      Volver
    </button>
  </mat-card-actions>
</div>
