import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../auxiliares.module';
import { HttpService } from '../http.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  standalone: true,
  imports: [CommonModule, AuxiliaresModule],
})
export class UploadFileComponent {
  @Input() public disabled: boolean = false;
  @Input() public textoBoton: string = 'Subir Imagen';
  @Input() url?: string;
  @Output() urlChange = new EventEmitter<string>();

  constructor(
    private dialogService: DialogService,
    private http: HttpService,
  ) {}

  public async setFile(event: Event): Promise<void> {
    const resp = await this.dialogService.confirm(
      'Subir Imagen',
      '¿Está seguro que desea subir la imagen?',
    );
    if (!resp) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const file = (event as any).target?.files?.item(0);
    const nombreCompleto = file?.name;
    const ext = nombreCompleto?.split('.').pop();
    const name = nombreCompleto?.split('.')[0];
    const nombreImagen = `${name}_${Date.now()}.${ext}`;

    const res = await this.subirImagen(file, nombreImagen);
    this.url = res.url;
    this.urlChange.emit(this.url);
  }

  /// Subir Imagen

  private async subirImagen(
    file: File,
    nombreImagen: string,
    folder = 'imagenes',
  ): Promise<{ url: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', nombreImagen);
    formData.append('folder', folder);

    return await firstValueFrom(this.http.post(`/storage/upload`, formData));
  }
}
