import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IEmpresa,
  ICreateEmpresa,
  IListado,
  IQueryMongo,
  IUpdateEmpresa,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class EmpresasService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryMongo): Observable<IListado<IEmpresa>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/empresas`, { params });
  }

  public crear(dato: ICreateEmpresa): Observable<IEmpresa> {
    return this.http.post(`/empresas`, dato);
  }

  public listarPorId(id: string): Observable<IEmpresa> {
    return this.http.get(`/empresas/${id}`);
  }

  public editar(id: string, dato: IUpdateEmpresa): Observable<IEmpresa> {
    return this.http.put(`/empresas/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/empresas/${id}`);
  }
}
