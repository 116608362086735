import { Component, OnInit } from '@angular/core';
import { ResumenService } from '../resumen.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public loading = false;

  constructor(private resumenService: ResumenService) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.loading = false;
  }
}
