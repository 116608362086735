<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<div class="mat-elevation-z8" style="margin: 1em">
  <!-- Filtro y columnas -->
  <div fxLayout="row" fxLayoutAlign="space-between center" style="padding: 1em">
    <!-- Filtros -->
    <app-filtro-tabla
      fxFlex
      [search]="search"
      [datos]="[
        empresa,
        segmento,
        subsegmento,
        familiaQuimica,
        principioActivo,
      ]"
      (onFilterChange)="cambioFiltro($event)"
    >
    </app-filtro-tabla>
    <!-- Columnas -->
    <app-column-select
      [(columns)]="columnas"
      [columnNames]="nombreColumnas"
      [saveName]="saveColumnas"
    >
    </app-column-select>
    <!-- Boton Exportar -->
    <button
      style="margin-right: 0.5em"
      mat-fab
      matTooltip="Exportar"
      color="accent"
      (click)="exportar()"
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <span>XLS</span>
        <mat-icon>download</mat-icon>
      </div>
    </button>
    <!-- Boton Importar -->
    <button
      style="margin-right: 0.5em"
      mat-fab
      matTooltip="Importar"
      color="accent"
      (click)="importar()"
    >
      <div fxLayout="column" fxLayoutAlign="center center">
        <span>CSV</span>
        <mat-icon>upload</mat-icon>
      </div>
    </button>
    <!-- Boton Crear -->
    <button mat-fab matTooltip="Crear" color="primary" [routerLink]="['crear']">
      <mat-icon>add</mat-icon>
    </button>
  </div>

  <!-- Tabla -->
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="sortChange($event)"
  >
    <!-- Columna Nro. Registro-->
    <ng-container matColumnDef="numeroRegistro">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Nro. Registro
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.numeroRegistro }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Empresa-->
    <ng-container matColumnDef="empresa.nombre">
      <mat-header-cell *matHeaderCellDef> Empresa </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.empresa?.nombre }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Producto-->
    <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Producto
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.nombre }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Segmento-->
    <ng-container matColumnDef="segmento.nombre">
      <mat-header-cell *matHeaderCellDef> Segmento </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.segmento?.nombre }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Subsegmento-->
    <ng-container matColumnDef="subsegmentos">
      <mat-header-cell *matHeaderCellDef> Subsegmentos </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <ng-container *ngFor="let c of row.subsegmentos">
            <span>{{ c.nombre }}</span>
            <br />
          </ng-container>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Composición-->
    <ng-container matColumnDef="composicion">
      <mat-header-cell *matHeaderCellDef> Composición </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div>
          <ng-container *ngFor="let c of row.composicion">
            <span>{{ c.principioActivo?.nombre }} {{ c.concentracion }}%</span>
            <br />
          </ng-container>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Columna Unidad-->
    <ng-container matColumnDef="unidad">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Unidad
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.unidad }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Formulación-->
    <ng-container matColumnDef="formulacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Formulación
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.formulacion }}</span>
      </mat-cell>
    </ng-container>
    <!-- Columna dosis-->
    <ng-container matColumnDef="dosisMedia">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Dosis
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.dosisMedia }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Clase Tox.-->
    <ng-container matColumnDef="claseTox">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Clase Tox.
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span>{{ row.claseTox }}</span>
      </mat-cell>
    </ng-container>

    <!-- Columna Acciones -->
    <ng-container matColumnDef="acciones">
      <mat-header-cell *matHeaderCellDef> Acciones </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        style="overflow: visible"
        fxLayoutGap="5px"
      >
        <button
          mat-mini-fab
          matTooltip="Editar"
          color="accent"
          [routerLink]="['editar', row._id]"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-mini-fab
          color="warn"
          matTooltip="Eliminar"
          (click)="eliminar(row)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnas; sticky: true"> </mat-header-row>
    <mat-row *matRowDef="let row; columns: columnas"> </mat-row>
  </mat-table>
  <mat-paginator-goto
    [length]="totalCount"
    [pageSize]="helper.pageSize"
    [pageSizeOptions]="helper.pageSizeOptions"
    [showFirstLastButtons]="true"
    (page)="pageEvent($event)"
  ></mat-paginator-goto>
</div>
