import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICreateDepartamento,
  IDepartamento,
  IListado,
  IProvincia,
  IQueryMongo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { DepartamentosService } from '../departamentos.service';

@Component({
  selector: 'app-crear-editar-departamentos',
  templateUrl: './crear-editar-departamentos.component.html',
  styleUrls: ['./crear-editar-departamentos.component.scss'],
})
export class CrearEditarDepartamentosComponent implements OnInit {
  public loading = false;
  public form?: UntypedFormGroup;
  public title?: string;

  public provincias: IProvincia[] = [];

  // Listado Continuo
  public provincias$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDepartamento,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarDepartamentosComponent>,
    private helper: HelperService,
    private service: DepartamentosService,
    private listadosService: ListadosService
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Departamento' : 'Crear Departamento';
    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      idProvincia: [this.data?.idProvincia, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateDepartamento = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  // Listados

  private async listarProvincias(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.provincias$?.unsubscribe();
    this.provincias$ = this.listadosService
      .subscribe<IListado<IProvincia>>('provincias', query)
      .subscribe((data) => {
        this.provincias = data.datos;
        console.log(`listado de provincias`, data);
      });
    await this.listadosService.getLastValue('provincias', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await this.listarProvincias();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.provincias$?.unsubscribe();
  }
}
