import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { PrincipioActivosRoutingModule } from './principioActivos.routing.module';
import { ListadoPrincipioActivosComponent } from './listado-principio-activos/listado-principio-activos.component';
import { CrearEditarPrincipioActivosComponent } from './crear-editar-principio-activos/crear-editar-principio-activos.component';

@NgModule({
  declarations: [
    ListadoPrincipioActivosComponent,
    CrearEditarPrincipioActivosComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, PrincipioActivosRoutingModule],
})
export class PrincipioActivosModule {}
