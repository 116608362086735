import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { FamiliaQuimicasRoutingModule } from './familiaQuimicas.routing.module';
import { ListadoFamiliaQuimicasComponent } from './listado-familia-quimicas/listado-familia-quimicas.component';
import { CrearEditarFamiliaQuimicasComponent } from './crear-editar-familia-quimicas/crear-editar-familia-quimicas.component';

@NgModule({
  declarations: [
    ListadoFamiliaQuimicasComponent,
    CrearEditarFamiliaQuimicasComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, FamiliaQuimicasRoutingModule],
})
export class FamiliaQuimicasModule {}
