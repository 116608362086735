import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IFilter, IListado, IQueryMongo, ISubsegmento } from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarSubsegmentosComponent } from '../crear-editar-subsegmentos/crear-editar-subsegmentos.component';
import { SubsegmentosService } from '../subsegmentos.service';

@Component({
  selector: 'app-listado-subsegmentos',
  templateUrl: './listado-subsegmentos.component.html',
  styleUrls: ['./listado-subsegmentos.component.scss'],
})
export class ListadoSubsegmentosComponent implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<ISubsegmento>([]);
  public totalCount = 0;
  public columnas: string[] = ['nombre', 'idSegmento', 'acciones'];
  public nombreColumnas: string[] = ['Subsegmento', 'Segmento', 'Acciones'];
  public saveColumnas = 'precios-admin-subsegmentos';
  // Filtros Tabla
  public search: IFilter = {
    field: 'nombre',
    type: 'regex',
    value: '',
  };

  private populate = {
    path: 'segmento',
    select: 'nombre',
  };
  public queryParams: IQueryMongo = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'nombre',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public datos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: SubsegmentosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter[]) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async crear(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarSubsegmentosComponent, config);
  }

  public async editar(data: ISubsegmento) {
    const config: MatDialogConfig = {
      data,
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      // hasBackdrop: false,
      disableClose: true,
    };
    this.matDialog.open(CrearEditarSubsegmentosComponent, config);
  }

  public async eliminar(dato: ISubsegmento): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el subsegmento ${dato.nombre}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<ISubsegmento>>('subsegmentos', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de subsegmentos`, data);
      });
    await this.listadosService.getLastValue('subsegmentos', this.queryParams);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([this.listar()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
  }
}
