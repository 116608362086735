import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ILocalidad,
  ICreateLocalidad,
  IListado,
  IQueryMongo,
  IUpdateLocalidad,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class LocalidadsService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryMongo): Observable<IListado<ILocalidad>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/localidads`, { params });
  }

  public async exportar(
    queryParams?: IQueryMongo,
    filename = 'localidades.xlsx'
  ): Promise<void> {
    let params = HelperService.getQueryParams(queryParams);
    await this.http.getFile(`/localidads/exportar`, filename, params);
  }

  public crear(dato: ICreateLocalidad): Observable<ILocalidad> {
    return this.http.post(`/localidads`, dato);
  }

  public listarPorId(id: string): Observable<ILocalidad> {
    return this.http.get(`/localidads/${id}`);
  }

  public editar(id: string, dato: IUpdateLocalidad): Observable<ILocalidad> {
    return this.http.put(`/localidads/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/localidads/${id}`);
  }
}
