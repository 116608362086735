import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  IEmpresa,
  IFamiliaQuimica,
  IFilter,
  IListado,
  IPrincipioActivo,
  IProducto,
  IQueryMongo,
  ISegmento,
  ISubsegmento,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { IFiltroTabla } from '../../../auxiliares/filtro-tabla/filtro-tabla.component';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { ImportarComponent } from '../importar/importar.component';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-listado-productos',
  templateUrl: './listado-productos.component.html',
  styleUrls: ['./listado-productos.component.scss'],
})
export class ListadoProductosComponent implements OnInit {
  public loading = true;

  // TABLA
  public dataSource = new MatTableDataSource<IProducto>([]);
  public totalCount = 0;
  public columnas: string[] = [
    'numeroRegistro',
    'empresa.nombre',
    'nombre',
    'segmento.nombre',
    'subsegmentos',
    'composicion',
    'unidad',
    'formulacion',
    'claseTox',
    'dosisMedia',
    'acciones',
  ];
  public nombreColumnas: string[] = [
    'Nro. Registro',
    'Empresa',
    'Producto',
    'Segmento',
    'subsegmentos',
    'Composición',
    'Unidad',
    'Formulación',
    'Clase Tox.',
    'Dosis',
    'Acciones',
  ];
  public saveColumnas = 'precios-admin-productos';
  // Filtros Tabla
  public search: IFilter = {
    field: 'nombre',
    type: 'regex',
    value: '',
  };
  public empresa: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idEmpresa',
      type: 'objectid',
      value: '',
    },
    label: 'Empresa',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public segmento: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idSegmento',
      type: 'objectid',
      value: '',
    },
    label: 'Segmento',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public subsegmento: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idsSubsegmentos',
      type: 'objectid',
      value: '',
    },
    label: 'Subsegmento',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public familiaQuimica: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idFamiliaQuimica',
      type: 'objectid',
      value: '',
    },
    label: 'Familia Quimica',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };
  public principioActivo: IFiltroTabla = {
    elementos: [],
    filter: {
      field: 'idPrincipioActivo',
      type: 'objectid',
      value: '',
    },
    label: 'Principio Activo',
    selectLabel: 'nombre',
    selectValue: '_id',
    tipo: 'select',
  };

  // QUERY+
  private populate = [
    {
      path: 'empresa',
      select: 'nombre',
    },
    {
      path: 'segmento',
      select: 'nombre',
    },
    {
      path: 'subsegmentos',
      select: 'nombre',
    },
    {
      path: 'composicion.familiaQuimica',
      select: 'nombre',
    },
    {
      path: 'composicion.principioActivo',
      select: 'nombre',
    },
  ];
  public queryParams: IQueryMongo = {
    page: 0,
    limit: +this.helper.pageSize,
    sort: 'nombre',
    populate: JSON.stringify(this.populate),
  };

  // Listado Continuo
  public datos$?: Subscription;
  public empresas$?: Subscription;
  public segmentos$?: Subscription;
  public subsegmentos$?: Subscription;
  public familiaQuimicas$?: Subscription;
  public principioActivos$?: Subscription;

  constructor(
    private dialogService: DialogService,
    private service: ProductosService,
    public matDialog: MatDialog,
    public helper: HelperService,
    private listadosService: ListadosService
  ) {}

  // ##############################################################################
  // ################################# TABLA ######################################
  // ##############################################################################
  // Filtro y paginacion
  public async pageEvent(event: PageEvent): Promise<void> {
    this.loading = true;
    this.helper.pageEvent(event);
    this.queryParams.page = event.pageIndex;
    this.queryParams.limit = event.pageSize;
    await this.listar();
    this.loading = false;
  }
  public async sortChange(event: Sort): Promise<void> {
    this.loading = true;
    this.queryParams.sort =
      event.direction === 'asc' ? event.active : `-${event.active}`;
    await this.listar();
    this.loading = false;
  }
  public async cambioFiltro(filters: IFilter[]) {
    this.loading = true;
    this.queryParams = {
      page: 0,
      limit: this.queryParams.limit,
      sort: this.queryParams.sort,
      populate: this.queryParams.populate,
      select: this.queryParams.select,
      filter: JSON.stringify(filters),
    };
    await this.listar();
    this.loading = false;
  }

  // ACCIONES
  public async eliminar(dato: IProducto): Promise<void> {
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      `¿Eliminar el producto ${dato.nombre}?`
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.eliminar(dato._id).pipe(first()).toPromise();
        this.helper.notifSuccess('Eliminación correcta');
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  public async exportar() {
    const mensaje = `¿Desea exportar el listado de productos?`;
    const confirm = await this.dialogService.confirm(
      'Confirme la acción',
      mensaje
    );
    if (confirm) {
      this.loading = true;
      try {
        await this.service.exportar(this.queryParams);
      } catch (error) {
        this.helper.notifError(error);
      }
      this.loading = false;
    }
  }

  public async importar() {
    const config: MatDialogConfig = {
      width: '1200px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      disableClose: true,
    };
    this.matDialog.open(ImportarComponent, config);
  }

  // Listar

  public async actualizar(): Promise<void> {
    this.loading = true;
    await this.listar();
    this.loading = false;
  }

  private async listar(): Promise<void> {
    this.datos$?.unsubscribe();
    this.datos$ = this.listadosService
      .subscribe<IListado<IProducto>>('productos', this.queryParams)
      .subscribe((data) => {
        this.totalCount = data.totalCount;
        this.dataSource.data = data.datos;
        console.log(`listado de productos`, data);
      });
    await this.listadosService.getLastValue('productos', this.queryParams);
  }

  private async listarEmpresas(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.empresas$?.unsubscribe();
    this.empresas$ = this.listadosService
      .subscribe<IListado<IEmpresa>>('empresas', query)
      .subscribe((data) => {
        this.empresa.elementos = data.datos;
        console.log(`listado de empresas`, data);
      });
    await this.listadosService.getLastValue('empresas', query);
  }

  private async listarSegmentos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.segmentos$?.unsubscribe();
    this.segmentos$ = this.listadosService
      .subscribe<IListado<ISegmento>>('segmentos', query)
      .subscribe((data) => {
        this.segmento.elementos = data.datos;
        console.log(`listado de segmentos`, data);
      });
    await this.listadosService.getLastValue('segmentos', query);
  }

  private async listarSubsegmentos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.subsegmentos$?.unsubscribe();
    this.subsegmentos$ = this.listadosService
      .subscribe<IListado<ISubsegmento>>('subsegmentos', query)
      .subscribe((data) => {
        this.subsegmento.elementos = data.datos;
        console.log(`listado de subsegmentos`, data);
      });
    await this.listadosService.getLastValue('subsegmentos', query);
  }

  private async listarPrincipioActivos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.principioActivos$?.unsubscribe();
    this.principioActivos$ = this.listadosService
      .subscribe<IListado<IPrincipioActivo>>('principioActivos', query)
      .subscribe((data) => {
        this.principioActivo.elementos = data.datos;
        console.log(`listado de principioActivos`, data);
      });
    await this.listadosService.getLastValue('principioActivos', query);
  }

  private async listarFamiliaQuimicas(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.familiaQuimicas$?.unsubscribe();
    this.familiaQuimicas$ = this.listadosService
      .subscribe<IListado<IFamiliaQuimica>>('familiaQuimicas', query)
      .subscribe((data) => {
        this.familiaQuimica.elementos = data.datos;
        console.log(`listado de familiaQuimicas`, data);
      });
    await this.listadosService.getLastValue('familiaQuimicas', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await Promise.all([
      this.listar(),
      this.listarEmpresas(),
      this.listarSegmentos(),
      this.listarSubsegmentos(),
      this.listarPrincipioActivos(),
      this.listarFamiliaQuimicas(),
    ]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.datos$?.unsubscribe();
    this.empresas$?.unsubscribe();
    this.segmentos$?.unsubscribe();
    this.subsegmentos$?.unsubscribe();
    this.familiaQuimicas$?.unsubscribe();
    this.principioActivos$?.unsubscribe();
  }
}
