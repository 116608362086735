<div fxLayout="row" fxLayoutAlign="end center" fxFill class="mat-paginator">
  <mat-paginator
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [showFirstLastButtons]="showFirstLastButtons"
    (page)="paginationChange($event)"
  >
  </mat-paginator>
  <div class="go-to-container">
    <div class="go-to-label">Ir a:</div>
    <mat-form-field>
      <mat-select [(ngModel)]="goTo" (selectionChange)="goToChange()">
        <mat-option
          *ngFor="let pageNumber of pageNumbers"
          [value]="pageNumber"
          >{{ pageNumber }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</div>
