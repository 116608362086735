import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ProvinciasRoutingModule } from './provincias.routing.module';
import { ListadoProvinciasComponent } from './listado-provincias/listado-provincias.component';
import { CrearEditarProvinciasComponent } from './crear-editar-provincias/crear-editar-provincias.component';

@NgModule({
  declarations: [ListadoProvinciasComponent, CrearEditarProvinciasComponent],
  imports: [CommonModule, AuxiliaresModule, ProvinciasRoutingModule],
})
export class ProvinciasModule {}
