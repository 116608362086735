<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form
      id="loginFamiliaQuimica"
      [formGroup]="form!"
      (ngSubmit)="onSubmit()"
      class="full-width"
    >
      <!-- Nombre Cliente  -->
      <mat-form-field class="full-width">
        <mat-label>Nombre</mat-label>
        <input
          matInput
          placeholder="Nombre"
          formControlName="nombre"
          required
        />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginFamiliaQuimica"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="close()">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
