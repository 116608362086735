import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  IComposicion,
  ICreateEmpresa,
  ICreateFamiliaQuimica,
  ICreatePrincipioActivo,
  ICreateProducto,
  ICreateSegmento,
  ICreateSubsegmento,
  IEmpresa,
  IFamiliaQuimica,
  IListado,
  IPrincipioActivo,
  IQueryMongo,
  ISegmento,
  ISubsegmento,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { EmpresasService } from '../../empresas/empresas.service';
import { FamiliaQuimicasService } from '../../familiaQuimicas/familiaQuimicas.service';
import { PrincipioActivosService } from '../../principioActivos/principioActivos.service';
import { SegmentosService } from '../../segmentos/segmentos.service';
import { SubsegmentosService } from '../../subsegmentos/subsegmentos.service';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-importar',
  templateUrl: './importar.component.html',
  styleUrls: ['./importar.component.scss'],
})
export class ImportarComponent implements OnInit {
  @ViewChild('lineCounter') lineCounter?: HTMLTextAreaElement;
  @ViewChild('textArea') textArea?: HTMLTextAreaElement;

  public loading = false;
  public form?: UntypedFormGroup;

  public lineCountCache = 0;
  public lineCounterValue = '1.';

  public empresas: IEmpresa[] = [];
  public segmentos: ISegmento[] = [];
  public subsegmentos: ISubsegmento[] = [];
  public principioActivos: IPrincipioActivo[] = [];
  public familiaQuimicas: IFamiliaQuimica[] = [];

  // Listado Continuo
  public empresas$?: Subscription;
  public segmentos$?: Subscription;
  public subsegmentos$?: Subscription;
  public principioActivos$?: Subscription;
  public familiaQuimicas$?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ImportarComponent>,
    private helper: HelperService,
    private dialogService: DialogService,
    private listadosService: ListadosService,
    private empresasService: EmpresasService,
    private segmentosService: SegmentosService,
    private productosService: ProductosService,
    private familiasQuimicasService: FamiliaQuimicasService,
    private principiosActivosService: PrincipioActivosService,
    private subsegmentosService: SubsegmentosService
  ) {}

  public setLineas(lineCount: number) {
    const outarr = new Array();
    if (this.lineCountCache != lineCount) {
      for (var x = 0; x < lineCount; x++) {
        outarr[x] = x + 1 + '.';
      }
      this.lineCounterValue = outarr.join('\n');
    }
    this.lineCountCache = lineCount;
  }

  public getErrorMessage() {
    if (this.form?.get('csv')?.hasError('error')) {
      return this.form?.get('csv')?.getError('error');
    }
    return;
  }

  private separarEnLineas(input: string): string[] | void {
    input = input.trim();
    const returnArray = [];
    if (input) {
      let inputArray: string[] = input.split('\n');
      inputArray = inputArray.map(
        Function.prototype.call,
        String.prototype.trim
      );
      for (const linea of inputArray) {
        if (linea) {
          returnArray.push(linea);
        }
      }
      if (returnArray.length) {
        return returnArray;
      }
    }
    return;
  }
  private parsearLinea(input: string): string[] | void {
    input = input.trim();
    if (input) {
      let inputArray: string[] = input.split(';');
      inputArray = inputArray.map(
        Function.prototype.call,
        String.prototype.trim
      );
      return inputArray;
    }
    return;
  }

  private validarTextarea(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const unidades: string[] = ['Lt', 'Kg', 'Pack', 'Otro'];
      const formulaciones: string[] = [
        'CS',
        'EC',
        'EW',
        'FS',
        'GR',
        'ME',
        'MR',
        'OD',
        'SC',
        'SE',
        'SG',
        'SL',
        'SP',
        'WG',
        'WP',
      ];
      const clasesTox: string[] = ['N/A', 'I', 'Ia', 'Ib', 'II', 'III', 'IV'];

      const input: string = control.value;
      const lineas = this.separarEnLineas(input);
      if (lineas) {
        for (let i = 0; i < lineas.length; i++) {
          const linea = lineas[i];
          const lineaParseada = this.parsearLinea(linea);
          if (!lineaParseada) {
            return { error: `Linea ${i + 1}. Formato incorrecto` };
          }
          if (lineaParseada.length !== 11) {
            return {
              error: `Linea ${i + 1}. Cantidad de parametros ${
                lineaParseada.length
              } incorrecta`,
            };
          }
          if (lineaParseada[3] && !unidades.includes(lineaParseada[3])) {
            return {
              error: `Linea ${
                i + 1
              }. El parametro unidad no es valido. Valores válidos: ${unidades.join(
                `, `
              )}`,
            };
          }
          if (lineaParseada[8]) {
            const composiciones = lineaParseada[8].split('+');
            // Comprobar si todos los elementos son numeros
            for (const comp of composiciones) {
              if (!+comp) {
                return {
                  error: `Linea ${
                    i + 1
                  }. El parametro composición debe ser un numero`,
                };
              }
            }
          }
          // if (!formulaciones.includes(lineaParseada[9])) {
          //   return {
          //     error: `Linea ${
          //       i + 1
          //     }. El parametro formulacion no es valido. Valores válidos: ${formulaciones.join(
          //       `, `
          //     )}`,
          //   };
          // }
          if (lineaParseada[10] && !clasesTox.includes(lineaParseada[10])) {
            return {
              error: `Linea ${
                i + 1
              }. El parametro clase tox no es valido. Valores válidos: ${clasesTox.join(
                `, `
              )}`,
            };
          }
        }
      }
      return null;
    };
  }

  //

  private createForm(): void {
    this.form = this.fb.group({
      csv: ['', [Validators.required, this.validarTextarea()]],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //
  private async getCreateData(linea: string) {
    const lineaParseada = this.parsearLinea(linea);
    // Nro. Registro ; Empresa ; Marca ; Unidad ; Segmento ; Subsegmentos ; Principio Activo ; Familia Química ; Concentración ; Formulación ; Clase Tox.
    const numeroRegistro = lineaParseada?.[0] || '';
    const nombreEmpresa = lineaParseada?.[1] || '';
    const nombre = lineaParseada?.[2] || '';
    const unidad = lineaParseada?.[3] || '';
    const nombreSegmento = lineaParseada?.[4] || '';
    const nombreSubsegmentos = lineaParseada?.[5] || '';
    const nombrePrincipioActivo = lineaParseada?.[6] || '';
    const nombreFamiliaQuimica = lineaParseada?.[7] || '';
    const concentracion = lineaParseada?.[8] || '';
    const formulacion = lineaParseada?.[9] || '';
    const claseTox = lineaParseada?.[10] || '';

    let nombreSubsegmentosArr: string[] = nombreSubsegmentos?.split(';') || [];
    nombreSubsegmentosArr = nombreSubsegmentosArr.map(
      Function.prototype.call,
      String.prototype.trim
    );
    let nombrePrincipioActivosArr: string[] =
      nombrePrincipioActivo?.split(';') || [];
    nombrePrincipioActivosArr = nombrePrincipioActivosArr.map(
      Function.prototype.call,
      String.prototype.trim
    );
    let nombreFamiliaQuimicaArr: string[] =
      nombreFamiliaQuimica?.split(';') || [];
    nombreFamiliaQuimicaArr = nombreFamiliaQuimicaArr.map(
      Function.prototype.call,
      String.prototype.trim
    );

    let empresa = this.empresas.find((e) => e.nombre === nombreEmpresa);
    let segmento = this.segmentos.find((s) => s.nombre === nombreSegmento);
    let concentracionArr: string[] = concentracion?.split(';') || [];
    concentracionArr = concentracionArr.map(
      Function.prototype.call,
      String.prototype.trim
    );

    if (!empresa) {
      if (nombreEmpresa) {
        const data: ICreateEmpresa = {
          nombre: nombreEmpresa,
        };
        empresa = await this.empresasService
          .crear(data)
          .pipe(take(1))
          .toPromise();
      }
    }
    if (!segmento) {
      if (nombreSegmento) {
        const data: ICreateSegmento = {
          nombre: nombreSegmento,
        };
        segmento = await this.segmentosService
          .crear(data)
          .pipe(take(1))
          .toPromise();
      }
    }

    const idsSubsegmentos = [];
    for (const nombre of nombreSubsegmentosArr) {
      if (nombre && segmento) {
        let subsegmento = this.subsegmentos.find((s) => s.nombre === nombre);
        if (!subsegmento) {
          const data: ICreateSubsegmento = {
            nombre,
            idSegmento: segmento._id,
          };
          subsegmento = await firstValueFrom(
            this.subsegmentosService.crear(data)
          );
        }
        idsSubsegmentos.push(subsegmento._id);
      }
    }

    const composicion: IComposicion[] = [];
    if (
      nombrePrincipioActivosArr.length === nombreFamiliaQuimicaArr.length &&
      nombreFamiliaQuimicaArr.length === concentracionArr.length
    ) {
      for (let i = 0; i < nombrePrincipioActivosArr.length; i++) {
        const nombreFamiliaQuimica = nombreFamiliaQuimicaArr[i];
        const nombrePrincipioActivo = nombrePrincipioActivosArr[i];
        const concentracion = +concentracionArr[i];

        if (nombreFamiliaQuimica && nombrePrincipioActivo && concentracion) {
          let familiaQuimica = this.familiaQuimicas.find(
            (f) => f.nombre === nombreFamiliaQuimica
          );
          if (!familiaQuimica) {
            const data: ICreateFamiliaQuimica = {
              nombre: nombreFamiliaQuimica,
            };
            familiaQuimica = await firstValueFrom(
              this.familiasQuimicasService.crear(data)
            );
          }

          let principioActivo = this.principioActivos.find(
            (p) => p.nombre === nombrePrincipioActivo
          );
          if (!principioActivo) {
            const data: ICreatePrincipioActivo = {
              nombre: nombrePrincipioActivo,
              idFamiliaQuimica: familiaQuimica._id,
            };
            principioActivo = await this.principiosActivosService
              .crear(data)
              .pipe(take(1))
              .toPromise();
          }

          const data: IComposicion = {
            idPrincipioActivo: principioActivo?._id,
            concentracion,
            idFamiliaQuimica: familiaQuimica._id,
          };
          composicion.push(data);
        }
      }
    }

    const data: ICreateProducto = {
      numeroRegistro,
      nombre,
      claseTox,
      composicion,
      formulacion,
      idEmpresa: empresa?._id,
      idSegmento: segmento?._id,
      idsSubsegmentos,
      unidad,
    };
    return data;
  }

  private eliminarLineaDelTextArea(linea: string) {
    const input = this.form?.get('csv')?.value;
    if (input) {
      const lineas = input.split('\n');
      const index = lineas.indexOf(linea);
      if (index > -1) {
        lineas.splice(index, 1);
        this.form?.patchValue({ csv: lineas.join('\n') });
      }
      this.onInput();
    }
  }

  private scrollTextAreaTop() {
    const textArea = document.getElementById('textArea');
    if (textArea) {
      textArea.scrollTop = 0;
    }
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const textArea = this.form?.get('csv')?.value;
      const lineas = this.separarEnLineas(textArea);
      let creados = 0;
      if (lineas?.length) {
        this.scrollTextAreaTop();
        for (const linea of lineas) {
          const data = await this.getCreateData(linea);
          try {
            await this.productosService.crear(data).pipe(first()).toPromise();
            creados++;
            this.eliminarLineaDelTextArea(linea);
          } catch (err) {
            // Esto no funciona
            // const seguir = await this.dialogService.confirm(
            //   `Error al crear el producto ${data.nombre}`,
            //   `¿Desea continuar la ejecución con el resto de productos?`
            // );
            // if (!seguir) {
            //   break;
            // }
            break;
            console.error(`Error al crear producto: ${linea}`);
            console.error(err);
          }
        }
        if (creados === lineas.length) {
          this.helper.notifSuccess(
            `Se crearon ${creados} de ${lineas?.length} productos`
          );
        } else if (creados) {
          this.helper.notifWarn(
            `Se crearon ${creados} de ${lineas?.length} productos`
          );
        } else {
          this.helper.notifError('No se crearon productos');
        }
      }
      // this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  // Listados

  private async listarSegmentos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.segmentos$?.unsubscribe();
    this.segmentos$ = this.listadosService
      .subscribe<IListado<ISegmento>>('segmentos', query)
      .subscribe((data) => {
        this.segmentos = data.datos;
        console.log(`listado de segmentos`, data);
      });
    await this.listadosService.getLastValue('segmentos', query);
  }

  private async listarSubsegmentos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.subsegmentos$?.unsubscribe();
    this.subsegmentos$ = this.listadosService
      .subscribe<IListado<ISubsegmento>>('subsegmentos', query)
      .subscribe((data) => {
        this.subsegmentos = data.datos;
        console.log(`listado de subsegmentos`, data);
      });
    await this.listadosService.getLastValue('subsegmentos', query);
  }

  private async listarEmpresas(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.empresas$?.unsubscribe();
    this.empresas$ = this.listadosService
      .subscribe<IListado<IEmpresa>>('empresas', query)
      .subscribe((data) => {
        this.empresas = data.datos;
        console.log(`listado de empresas`, data);
      });
    await this.listadosService.getLastValue('empresas', query);
  }

  private async listarFamiliaQuimicas(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.familiaQuimicas$?.unsubscribe();
    this.familiaQuimicas$ = this.listadosService
      .subscribe<IListado<IFamiliaQuimica>>('familiaQuimicas', query)
      .subscribe((data) => {
        this.familiaQuimicas = data.datos;
        console.log(`listado de familiaQuimicas`, data);
      });
    await this.listadosService.getLastValue('familiaQuimicas', query);
  }

  private async listarPrincipioActivos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.principioActivos$?.unsubscribe();
    this.principioActivos$ = this.listadosService
      .subscribe<IListado<IPrincipioActivo>>('principioActivos', query)
      .subscribe((data) => {
        this.principioActivos = data.datos;
        console.log(`listado de principioActivos`, data);
      });
    await this.listadosService.getLastValue('principioActivos', query);
  }

  public onScroll() {
    const textArea = document.getElementById('textArea');
    const lineCounter = document.getElementById('lineCounter');
    if (textArea && lineCounter) {
      lineCounter.scrollTop = textArea.scrollTop;
      lineCounter.scrollLeft = textArea.scrollLeft;
    }
  }

  public onInput() {
    const input = this.form?.get('csv')?.value;
    const lineCount = input.split('\n').length || 0;
    this.setLineas(lineCount);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await Promise.all([
      this.listarSegmentos(),
      this.listarSubsegmentos(),
      this.listarEmpresas(),
      this.listarFamiliaQuimicas(),
      this.listarPrincipioActivos(),
    ]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.empresas$?.unsubscribe();
    this.segmentos$?.unsubscribe();
    this.subsegmentos$?.unsubscribe();
    this.principioActivos$?.unsubscribe();
    this.familiaQuimicas$?.unsubscribe();
  }
}
