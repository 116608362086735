import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent implements OnInit {
  title: string;
  message: string;
  inputName: string;
  btnOkText: string;
  btnCancelText: string;

  input = '';

  constructor(
    public matDialogRef: MatDialogRef<PromptComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.inputName = this.data.inputName;
    this.btnOkText = this.data.btnOkText || 'Si';
    this.btnCancelText = this.data.btnCancelText || 'No';
  }

  ngOnInit() {}
}
