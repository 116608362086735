import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../modulos/login/login.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class ClienteAdminGuard  {
  constructor(private router: Router, private helper: HelperService) {}

  canActivate() {
    const usuario = LoginService.getUsuario();
    if (usuario?.cliente?.tipo === 'Admin') {
      return true;
    } else {
      this.helper.notifWarn('Permiso de cliente denegado');
      return this.router.parseUrl('/login');
    }
  }

  canActivateChild() {
    const usuario = LoginService.getUsuario();
    if (usuario?.cliente?.tipo === 'Admin') {
      return true;
    } else {
      this.helper.notifWarn('Permiso de cliente denegado');
      return this.router.parseUrl('/login');
    }
  }
}
