import { DragDropModule } from '@angular/cdk/drag-drop';
import { provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from './dialog/dialog.module';
import { ColumnSelectModule } from './column-select/column-select.module';
import { FiltroTablaModule } from './filtro-tabla/filtro-tabla.module';
import { MaterialModule } from './material.module';
import { MatPaginatorGotoModule } from './mat-paginator-goto/mat-paginator-goto.module';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { NgxColorsModule } from 'ngx-colors';

@NgModule({
  imports: [
    // CommonModule,
    // SimpleNotificationsModule.forRoot()
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    FlexLayoutModule,
    MaterialModule,
    NgSelectModule,
    NgxColorsModule,
    //
    ColumnSelectModule,
    DialogModule,
    FiltroTablaModule,
    MatPaginatorGotoModule,
  ],
  providers: [provideHttpClient()],
})
export class AuxiliaresModule {
  constructor(private config: NgSelectConfig) {
    this.config.notFoundText = 'No se encontraron resultados';
    this.config.clearAllText = 'Eliminar todos';
    this.config.loadingText = 'Cargando...';
    // this.config.appendTo = 'body';
    // set the bindValue to global config when you use the same
    // bindValue in most of the place.
    // You can also override bindValue for the specified template
    // by defining `bindValue` as property
    // Eg : <ng-select bindValue="some-new-value"></ng-select>
  }
}
