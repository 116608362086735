import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ProductosRoutingModule } from './productos.routing.module';
import { ListadoProductosComponent } from './listado-productos/listado-productos.component';
import { CrearEditarProductosComponent } from './crear-editar-productos/crear-editar-productos.component';
import { ImportarComponent } from './importar/importar.component';

@NgModule({
  declarations: [
    ListadoProductosComponent,
    CrearEditarProductosComponent,
    ImportarComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, ProductosRoutingModule],
})
export class ProductosModule {}
