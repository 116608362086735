import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { DepartamentosRoutingModule } from './departamentos.routing.module';
import { ListadoDepartamentosComponent } from './listado-departamentos/listado-departamentos.component';
import { CrearEditarDepartamentosComponent } from './crear-editar-departamentos/crear-editar-departamentos.component';

@NgModule({
  declarations: [
    ListadoDepartamentosComponent,
    CrearEditarDepartamentosComponent,
  ],
  imports: [CommonModule, AuxiliaresModule, DepartamentosRoutingModule],
})
export class DepartamentosModule {}
