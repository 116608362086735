import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IPrincipioActivo,
  ICreatePrincipioActivo,
  IListado,
  IQueryMongo,
  IUpdatePrincipioActivo,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class PrincipioActivosService {
  constructor(private http: HttpService) {}

  public listar(
    queryParams?: IQueryMongo
  ): Observable<IListado<IPrincipioActivo>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/principioActivos`, { params });
  }

  public crear(dato: ICreatePrincipioActivo): Observable<IPrincipioActivo> {
    return this.http.post(`/principioActivos`, dato);
  }

  public listarPorId(id: string): Observable<IPrincipioActivo> {
    return this.http.get(`/principioActivos/${id}`);
  }

  public editar(
    id: string,
    dato: IUpdatePrincipioActivo
  ): Observable<IPrincipioActivo> {
    return this.http.put(`/principioActivos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/principioActivos/${id}`);
  }
}
