import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICliente, IToken, IUsuario } from 'modelos/src';
import { Observable, firstValueFrom } from 'rxjs';
import { api } from '../../../environments/environment';
import { HelperService } from '../../auxiliares/helper.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private http: HttpClient,
    private helper: HelperService,
  ) {}

  public setCliente(cliente?: ICliente) {
    localStorage.setItem('cliente', JSON.stringify(cliente));
  }

  public static getCliente(): ICliente | undefined {
    const c = localStorage.getItem('cliente');
    let cliente: ICliente | undefined;
    if (c) {
      cliente = JSON.parse(c) as ICliente;
    }
    return cliente;
  }

  public isLogged() {
    if (sessionStorage.getItem('token')) {
      return true;
    }
    return false;
  }

  public getToken() {
    return sessionStorage.getItem('token') || '';
  }

  public getRefreshToken(): string {
    return sessionStorage.getItem('refreshToken') || '';
  }

  public static getUsuario(): IUsuario {
    const user = sessionStorage.getItem('usuario');
    return user ? JSON.parse(user) : undefined;
  }

  public removeLoginInfo() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('usuario');
    sessionStorage.removeItem('cliente');
  }

  public setLoginInfo(token: IToken) {
    console.log('info login', token);
    sessionStorage.setItem('token', token.accessToken);
    sessionStorage.setItem('refreshToken', token.refreshToken || '');
    sessionStorage.setItem('usuario', JSON.stringify(token.user));
    this.setCliente(token.user.cliente);
  }

  // LOGIN

  public _login(username: string, password: string): Observable<IToken> {
    return this.http.post<IToken>(`${api}/auth/login`, {
      username,
      password,
    });
  }

  public async login(username: string, password: string): Promise<IToken> {
    try {
      const token = await firstValueFrom(this._login(username, password));
      this.setLoginInfo(token);
      return token;
    } catch (error) {
      this.helper.notifError(error);
      throw error;
    }
  }

  // REFRESH TOKEN

  private _refreshToken(refresh_token: string): Observable<IToken> {
    return this.http.post<IToken>(`${api}/auth/refresh_token`, {
      refresh_token,
    });
  }

  public async refreshToken(): Promise<IToken> {
    const refresh_token = this.getRefreshToken();
    const token = await firstValueFrom(this._refreshToken(refresh_token));
    this.setLoginInfo(token);
    return token;
    // "Invalid grant: refresh token is invalid"
  }
}
