import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { ClientesRoutingModule } from './clientes.routing.module';
import { ListadoClientesComponent } from './listado-clientes/listado-clientes.component';
import { CrearEditarClientesComponent } from './crear-editar-clientes/crear-editar-clientes.component';
import { UploadFileComponent } from 'src/app/auxiliares/upload-file/upload-file.component';

@NgModule({
  declarations: [ListadoClientesComponent, CrearEditarClientesComponent],
  imports: [
    CommonModule,
    AuxiliaresModule,
    ClientesRoutingModule,
    UploadFileComponent,
  ],
})
export class ClientesModule {}
