import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RolesGlobalesGuard } from '../../auxiliares/guards/rol.guard';
import { ClientesModule } from '../clientes/clientes.module';
import { DepartamentosModule } from '../departamentos/departamentos.module';
import { EmpresasModule } from '../empresas/empresas.module';
import { FamiliaQuimicasModule } from '../familiaQuimicas/familiaQuimicas.module';
import { LandingModule } from '../landing/landing.module';
import { LocalidadsModule } from '../localidads/localidads.module';
import { PrincipioActivosModule } from '../principioActivos/principioActivos.module';
import { ProductosModule } from '../productos/productos.module';
import { ProvinciasModule } from '../provincias/provincias.module';
import { SegmentosModule } from '../segmentos/segmentos.module';
import { SubsegmentosModule } from '../subsegmentos/subsegmentos.module';
import { UsuariosModule } from '../usuarios/usuarios.module';
import { NavigationComponent } from './navigation/navigation.component';

const routes: Routes = [
  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        path: 'landing',
        loadChildren: () => LandingModule,
      },
      {
        path: 'clientes',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => ClientesModule,
      },
      {
        path: 'usuarios',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => UsuariosModule,
      },
      {
        path: 'empresas',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => EmpresasModule,
      },
      {
        path: 'familiaQuimicas',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => FamiliaQuimicasModule,
      },
      {
        path: 'principioActivos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => PrincipioActivosModule,
      },
      {
        path: 'productos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => ProductosModule,
      },
      {
        path: 'provincias',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => ProvinciasModule,
      },
      {
        path: 'departamentos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => DepartamentosModule,
      },
      {
        path: 'localidads',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => LocalidadsModule,
      },
      {
        path: 'segmentos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => SegmentosModule,
      },
      {
        path: 'subsegmentos',
        canActivate: [RolesGlobalesGuard],
        data: { roles: ['Admin'] },
        loadChildren: () => SubsegmentosModule,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NavigationRoutingModule {}
