import { Injectable } from '@angular/core';

export interface ColumnInfo {
  id: string;
  name: string;
  hidden: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ColumnSelectService {

  loadSavedColumnInfo(columnInfo: ColumnInfo[], saveName?: string): ColumnInfo[] {
    // Only load if a save name is passed in
    if (saveName) {
      if (!localStorage) {
        return [];
      }

      const loadedInfo = localStorage.getItem(`${saveName}-columns`);

      if (loadedInfo) {
        let savedInfo: ColumnInfo[] = JSON.parse(loadedInfo);
        const columnNames = columnInfo.map(e => e.name);
        for (const col of columnInfo) {
          const existe = savedInfo.find( e => e.name === col.name);
          if (!existe) {
            savedInfo.push(col);
          }
        }
        savedInfo = savedInfo.filter( e => columnNames.includes(e.name));
        return savedInfo;
      }
      this.saveColumnInfo(columnInfo);
      return columnInfo;
    } else {
      return [];
    }
  }

  saveColumnInfo(columnInfo: ColumnInfo[], saveName?: string): void {
    // Only save if a save name is passed in
    if (saveName) {
      if (!localStorage) {
        return;
      }

      localStorage.setItem(`${saveName}-columns`, JSON.stringify(columnInfo));
    }
  }

}
