import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ISegmento,
  ICreateSegmento,
  IListado,
  IQueryMongo,
  IUpdateSegmento,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class SegmentosService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryMongo): Observable<IListado<ISegmento>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/segmentos`, { params });
  }

  public crear(dato: ICreateSegmento): Observable<ISegmento> {
    return this.http.post(`/segmentos`, dato);
  }

  public listarPorId(id: string): Observable<ISegmento> {
    return this.http.get(`/segmentos/${id}`);
  }

  public editar(id: string, dato: IUpdateSegmento): Observable<ISegmento> {
    return this.http.put(`/segmentos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/segmentos/${id}`);
  }
}
