<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title></mat-card-header
  >

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form
      id="formCliente"
      [formGroup]="form!"
      (ngSubmit)="onSubmit()"
      class="column-center"
      style="gap: 5px"
    >
      <div class="row-strech" style="gap: 5px; width: 100%">
        <!-- Nombre -->
        <mat-form-field style="width: 75%">
          <mat-label>Nombre</mat-label>
          <input
            matInput
            placeholder="Nombre"
            formControlName="nombre"
            required
          />
        </mat-form-field>

        <!-- Comparte Relevamientos -->
        <ng-container formGroupName="config">
          <mat-checkbox
            formControlName="comparteRelevamientos"
            style="margin: 0.5em 0"
          >
            Comparte Relevamientos
          </mat-checkbox>
        </ng-container>
      </div>

      <div class="row-center" style="gap: 5px; width: 100%">
        <!-- Tipo -->
        <mat-form-field style="width: 100%">
          <mat-label>Tipo</mat-label>
          <mat-select formControlName="tipo" required>
            <mat-option *ngFor="let dato of tipoClientes" [value]="dato">{{
              dato
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Empresas -->
        <mat-form-field style="width: 100%">
          <mat-label>Empresas Propias</mat-label>
          <mat-select formControlName="idsEmpresa" multiple>
            <mat-option *ngFor="let dato of empresas" [value]="dato._id">{{
              dato.nombre
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container formGroupName="tema">
        <mat-form-field style="width: 100%">
          <mat-label>Color Primario</mat-label>
          <input matInput readonly formControlName="primaryColor" />
          <ngx-colors
            class="suffix"
            matSuffix
            ngx-colors-trigger
            formControlName="primaryColor"
          >
          </ngx-colors>
        </mat-form-field>

        <mat-form-field style="width: 100%">
          <mat-label>Color Secundario</mat-label>
          <input matInput readonly formControlName="accentColor" />
          <ngx-colors
            class="suffix"
            matSuffix
            ngx-colors-trigger
            formControlName="accentColor"
          >
          </ngx-colors>
        </mat-form-field>

        <mat-form-field style="width: 100%">
          <mat-label>Color Advertencia</mat-label>
          <input matInput readonly formControlName="warnColor" />
          <ngx-colors
            class="suffix"
            matSuffix
            ngx-colors-trigger
            formControlName="warnColor"
          >
          </ngx-colors>
        </mat-form-field>
      </ng-container>

      <ng-container>
        <mat-form-field style="width: 100%">
          <mat-label>Logo</mat-label>
          <input
            matInput
            disabled
            readonly
            [(ngModel)]="logoUrl"
            [ngModelOptions]="{ standalone: true }"
          />
          <app-upload-file
            (url)="(logoUrl)"
            (urlChange)="onUrlLogoChanges($event)"
            matSuffix
          ></app-upload-file>
        </mat-form-field>
        @if (this.logoUrl) {
          <div class="row-center">
            <img [src]="logoUrl" style="height: 100px" />
          </div>
        }

        <mat-form-field style="width: 100%">
          <mat-label>Ícono</mat-label>
          <input
            matInput
            disabled
            readonly
            [(ngModel)]="iconoUrl"
            [ngModelOptions]="{ standalone: true }"
          />
          <app-upload-file
            (url)="(iconoUrl)"
            (urlChange)="onUrlIconoChanges($event)"
            matSuffix
          ></app-upload-file>
        </mat-form-field>
        @if (this.iconoUrl) {
          <div class="row-center">
            <img [src]="iconoUrl" style="height: 100px" />
          </div>
        }
      </ng-container>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="formCliente"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="formCliente"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
