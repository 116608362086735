import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICreateLocalidad,
  IDepartamento,
  IFilter,
  IListado,
  ILocalidad,
  IProvincia,
  IQueryMongo,
} from 'modelos/src';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { LocalidadsService } from '../localidads.service';

@Component({
  selector: 'app-crear-editar-localidads',
  templateUrl: './crear-editar-localidads.component.html',
  styleUrls: ['./crear-editar-localidads.component.scss'],
})
export class CrearEditarLocalidadsComponent implements OnInit {
  public loading = false;
  public form?: UntypedFormGroup;
  public title?: string;

  public provincias: IProvincia[] = [];
  public departamentos: IDepartamento[] = [];

  // Listado Continuo
  public provincias$?: Subscription;
  public departamentos$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ILocalidad,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarLocalidadsComponent>,
    private helper: HelperService,
    private service: LocalidadsService,
    private listadosService: ListadosService
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Localidad' : 'Crear Localidad';
    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      idProvincia: [this.data?.idProvincia, Validators.required],
      idDepartamento: [this.data?.idDepartamento, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateLocalidad = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  //

  public async cambioProvincia(): Promise<void> {
    this.form?.patchValue({ idDepartamento: undefined });
    await this.listarDepartamentos();
  }

  // Listados

  private async listarProvincias(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.provincias$?.unsubscribe();
    this.provincias$ = this.listadosService
      .subscribe<IListado<IProvincia>>('provincias', query)
      .subscribe((data) => {
        this.provincias = data.datos;
        console.log(`listado de provincias`, data);
      });
    await this.listadosService.getLastValue('provincias', query);
  }

  private async listarDepartamentos(): Promise<void> {
    const idProvincia = this.form?.value.idProvincia;
    if (idProvincia) {
      const filter: IFilter[] = [
        {
          field: 'idProvincia',
          type: 'objectid',
          value: idProvincia,
        },
      ];
      const query: IQueryMongo = {
        select: 'nombre idProvincia',
        sort: 'nombre',
        filter: JSON.stringify(filter),
      };
      this.departamentos$?.unsubscribe();
      this.departamentos$ = this.listadosService
        .subscribe<IListado<IDepartamento>>('departamentos', query)
        .subscribe((data) => {
          this.departamentos = data.datos;
          console.log(`listado de departamentos`, data);
        });
      await this.listadosService.getLastValue('departamentos', query);
    }
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await Promise.all([this.listarProvincias(), this.listarDepartamentos()]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.provincias$?.unsubscribe();
    this.departamentos$?.unsubscribe();
  }
}
