import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { DialogService } from '../../../auxiliares/dialog/dialog.service';
import { Router } from '@angular/router';
import { LogoutService } from '../../login/logout.service';
import { env } from '../../../../environments/environment';
import { ICliente, IUsuario } from 'modelos/src';
import { LoginService } from '../../login/login.service';
import { version } from '../../../../environments/version';
import { DOCUMENT } from '@angular/common';
import { ListadosService } from 'src/app/auxiliares/listados.service';
import { ThemesService } from 'src/app/auxiliares/themes.service';

type Envs = 'Local' | 'Dev' | 'Test' | 'Prod';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public usuario?: IUsuario;

  public cliente?: ICliente;
  public cliente$?: Subscription;

  public temaOscuro = localStorage.getItem('temaOscuro') === 'true';

  public version = version;
  public env: Envs = env;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogService: DialogService,
    private router: Router,
    private logoutService: LogoutService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private listados: ListadosService,
    private temas: ThemesService,
    private loginService: LoginService,
  ) {}

  private temaInicial() {
    if (localStorage.getItem('temaOscuro') === 'true') {
      this.renderer.addClass(this.document.body, 'dark-theme');
    }
  }

  public toggleTheme() {
    if (this.document.body.classList.contains('dark-theme')) {
      this.renderer.removeClass(this.document.body, 'dark-theme');
      localStorage.removeItem('temaOscuro');
    } else {
      this.renderer.addClass(this.document.body, 'dark-theme');
      localStorage.setItem('temaOscuro', 'true');
    }
  }

  public logout() {
    this.dialogService
      .confirm('Confirme la acción', '¿Desea cerrar la sesión?')
      .then((confirm) => {
        if (confirm) {
          this.router.navigateByUrl('login');
          this.logoutService.logout();
        }
      });
  }

  /// TEMA NUEVO

  private async clienteUpdate() {
    const id = LoginService.getCliente()?._id;
    if (!id) {
      console.log('No hay cliente');

      return;
    }
    try {
      this.cliente$?.unsubscribe();
      this.cliente$ = this.listados
        .subscribe<ICliente>('cliente', id)
        .subscribe((data) => {
          this.cliente = data;
          console.log('Actualizando variables del cliente');
          if (data) {
            this.temas.setTema(data);
          }
          this.loginService.setCliente(data);
        });
      await this.listados.getLastValue('cliente', id);
    } catch (error) {
      console.error(error);
    }
  }

  /// Hooks

  async ngOnInit(): Promise<void> {
    this.usuario = LoginService.getUsuario();
    this.temaInicial();
    await this.clienteUpdate();
  }
}
