<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav bg-85"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <!-- Menu -->
    <div fxLayout="row" fxLayoutAlign="start center" style="padding: 0 16px">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="(isHandset$ | async) === false"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <mat-toolbar>Menu</mat-toolbar>
    </div>
    <!-- Menu -->

    <!-- Usuario -->
    <div class="usuario">
      <h3 style="text-align: center">{{ usuario?.username }}</h3>
    </div>
    <!-- Usuario -->

    <!-- Elementos Menu -->
    <mat-nav-list>
      <a mat-list-item [routerLink]="'landing'" [routerLinkActive]="'active'">
        Inicio
      </a>
      <a mat-list-item [routerLink]="'clientes'" [routerLinkActive]="'active'">
        Clientes
      </a>
      <a mat-list-item [routerLink]="'usuarios'" [routerLinkActive]="'active'">
        Usuarios
      </a>

      <!-- Productos -->
      <mat-divider></mat-divider>
      <a mat-list-item [routerLink]="'productos'" [routerLinkActive]="'active'">
        Productos
      </a>

      <a mat-list-item [routerLink]="'empresas'" [routerLinkActive]="'active'">
        Empresas
      </a>
      <a
        mat-list-item
        [routerLink]="'familiaQuimicas'"
        [routerLinkActive]="'active'"
      >
        F. Química
      </a>
      <a
        mat-list-item
        [routerLink]="'principioActivos'"
        [routerLinkActive]="'active'"
      >
        P. Activo
      </a>
      <a mat-list-item [routerLink]="'segmentos'" [routerLinkActive]="'active'">
        Segmentos
      </a>
      <a
        mat-list-item
        [routerLink]="'subsegmentos'"
        [routerLinkActive]="'active'"
      >
        Subsegmentos
      </a>

      <!-- Zonas -->
      <mat-divider></mat-divider>
      <a
        mat-list-item
        [routerLink]="'provincias'"
        [routerLinkActive]="'active'"
      >
        Provincias
      </a>
      <a
        mat-list-item
        [routerLink]="'departamentos'"
        [routerLinkActive]="'active'"
      >
        Departamentos
      </a>
      <a
        mat-list-item
        [routerLink]="'localidads'"
        [routerLinkActive]="'active'"
      >
        Localidades
      </a>

      <!-- Salir -->
      <mat-divider></mat-divider>
      <a mat-list-item (click)="logout()">Salir</a>
    </mat-nav-list>
    <!-- Elementos Menu -->

    <mat-divider></mat-divider>

    <!-- Cambio de tema -->
    <mat-slide-toggle
      style="margin-top: 1em; padding: 0 16px"
      [checked]="temaOscuro"
      labelPosition="before"
      (toggleChange)="toggleTheme()"
    >
      Tema Oscuro
    </mat-slide-toggle>

    <!-- Version -->
    <h3 style="text-align: center; margin-top: 1em; padding: 0 16px">
      <span> Versión: {{ version }}</span>
      <span *ngIf="env !== 'Prod'"> ({{ env }})</span>
    </h3>
    <!-- Version -->
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="(isHandset$ | async) || !drawer.opened"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <img
        src="/assets/images/agroinsumos2.png"
        alt=""
        [height]="(isHandset$ | async) ? 50 : 60"
      />
      <span>Agroinsumos - Admin</span>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
