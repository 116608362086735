import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { NavigationRoutingModule } from './navigation-routing.module';

@NgModule({
  declarations: [NavigationComponent],
  imports: [
    CommonModule,
    LayoutModule,
    AuxiliaresModule,
    NavigationRoutingModule,
  ],
})
export class NavigationModule {}
