<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form
      id="formLocalidad"
      [formGroup]="form!"
      (ngSubmit)="onSubmit()"
      class="column-center"
    >
      <!-- Nombre Cliente  -->
      <mat-form-field class="full-width">
        <mat-label>Nombre</mat-label>
        <input
          matInput
          placeholder="Nombre"
          formControlName="nombre"
          required
        />
      </mat-form-field>

      <div class="row-center" style="gap: 5px; width: 100%">
        <!-- Provincia -->
        <mat-form-field style="width: 50%">
          <mat-label>Provincia</mat-label>
          <mat-select
            formControlName="idProvincia"
            required
            (selectionChange)="cambioProvincia()"
          >
            <mat-option *ngFor="let dato of provincias" [value]="dato._id">
              {{ dato.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Departamento -->
        <mat-form-field style="width: 50%">
          <mat-label>Departamento</mat-label>
          <mat-select formControlName="idDepartamento" required>
            <mat-option *ngFor="let dato of departamentos" [value]="dato._id">
              {{ dato.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="formLocalidad"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="close()">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
