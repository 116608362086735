import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  ICreatePrincipioActivo,
  IFamiliaQuimica,
  IListado,
  IPrincipioActivo,
  IQueryMongo,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarFamiliaQuimicasComponent } from '../../familiaQuimicas/crear-editar-familia-quimicas/crear-editar-familia-quimicas.component';
import { PrincipioActivosService } from '../principioActivos.service';

@Component({
  selector: 'app-crear-editar-principio-activos',
  templateUrl: './crear-editar-principio-activos.component.html',
  styleUrls: ['./crear-editar-principio-activos.component.scss'],
})
export class CrearEditarPrincipioActivosComponent implements OnInit {
  public loading = false;
  public form?: UntypedFormGroup;
  public title?: string;

  public familiaQuimicas: IFamiliaQuimica[] = [];

  // Listado Continuo
  public familiaQuimicas$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IPrincipioActivo,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CrearEditarPrincipioActivosComponent>,
    private helper: HelperService,
    private service: PrincipioActivosService,
    private listadosService: ListadosService,
    public matDialog: MatDialog
  ) {}

  private createForm(): void {
    this.title = this.data?._id
      ? 'Editar Principio Activo'
      : 'Crear Principio Activo';
    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      nombreCorto: [this.data?.nombreCorto],
      idFamiliaQuimica: [this.data?.idFamiliaQuimica, Validators.required],
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreatePrincipioActivo = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      let result: IPrincipioActivo;
      if (this.data?._id) {
        result = await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        result = await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(result);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  // Crear
  public async crearFamiliaQuimica(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      disableClose: true,
    };
    const modal = this.matDialog.open<
      CrearEditarFamiliaQuimicasComponent,
      any,
      IFamiliaQuimica
    >(CrearEditarFamiliaQuimicasComponent, config);
    const creado = await modal.afterClosed().pipe(take(1)).toPromise();
    if (creado) {
      this.form?.patchValue({ idFamiliaQuimica: creado._id });
    }
  }

  // Listados

  private async listarFamiliaQuimicas(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.familiaQuimicas$?.unsubscribe();
    this.familiaQuimicas$ = this.listadosService
      .subscribe<IListado<IFamiliaQuimica>>('familiaQuimicas', query)
      .subscribe((data) => {
        this.familiaQuimicas = data.datos;
        console.log(`listado de familiaQuimicas`, data);
      });
    await this.listadosService.getLastValue('familiaQuimicas', query);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await this.listarFamiliaQuimicas();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.familiaQuimicas$?.unsubscribe();
  }
}
