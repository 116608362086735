<mat-progress-bar
  [mode]="loading ? 'indeterminate' : 'determinate'"
  color="warn"
></mat-progress-bar>

<h1 style="margin: 2em; text-align: center">Bienvenido!!!</h1>
<h1 style="margin: 2em; text-align: center"></h1>

<!-- Detalles -->
<div fxLayout="row wrap"></div>
