import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ICliente,
  ICreateCliente,
  IEmpresa,
  IListado,
  IQueryMongo,
  TipoCliente,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { ClientesService } from '../clientes.service';

@Component({
  selector: 'app-crear-editar-clientes',
  templateUrl: './crear-editar-clientes.component.html',
  styleUrls: ['./crear-editar-clientes.component.scss'],
})
export class CrearEditarClientesComponent implements OnInit, OnDestroy {
  public loading = false;
  public form?: FormGroup;
  public title?: string;

  public logoUrl?: string;
  public iconoUrl?: string;

  public condicionesComerciales: string[] = [
    'Distribuidor',
    'Megafarmers',
    'Productor',
  ];

  public tipoClientes: TipoCliente[] = [
    'Admin',
    'Lector',
    'Relevador Full',
    'Relevador',
  ];
  public empresas: IEmpresa[] = [];

  // Listado Continuo
  public empresas$?: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICliente,
    private dialogRef: MatDialogRef<CrearEditarClientesComponent>,
    private helper: HelperService,
    private service: ClientesService,
    private listadosService: ListadosService,
  ) {}

  private createForm(): void {
    this.title = this.data?._id ? 'Editar Cliente' : 'Crear Cliente';
    this.form = new FormGroup({
      nombre: new FormControl(this.data?.nombre, Validators.required),
      tipo: new FormControl(this.data?.tipo, Validators.required),
      idsEmpresa: new FormControl(this.data?.idsEmpresa),
      config: new FormGroup({
        comparteRelevamientos: new FormControl(
          this.data?.config?.comparteRelevamientos || false,
        ),
      }),
      tema: new FormGroup({
        primaryColor: new FormControl(this.data?.tema?.primaryColor),
        accentColor: new FormControl(this.data?.tema?.accentColor),
        warnColor: new FormControl(this.data?.tema?.warnColor),
        // typography: new FormControl(this.data?.tema?.typography), /// TODO: Implementar en el tema.
      }),
      imagenes: new FormGroup({
        icono: new FormControl(this.data?.imagenes?.icono),
        logo: new FormControl(this.data?.imagenes?.logo),
      }),
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  //

  private getData() {
    const data: ICreateCliente = this.form?.value;
    return data;
  }

  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await firstValueFrom(this.service.editar(this.data._id, data));
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await firstValueFrom(this.service.crear(data));
        this.helper.notifSuccess('Creado correctamente');
      }
      this.dialogRef.close(true);
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  // Listados

  private async listarClientes(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.empresas$?.unsubscribe();
    this.empresas$ = this.listadosService
      .subscribe<IListado<IEmpresa>>('empresas', query)
      .subscribe((data) => {
        this.empresas = data.datos;
        console.log(`listado de empresas`, data);
      });
    await this.listadosService.getLastValue('empresas', query);
  }

  public onUrlLogoChanges(url: string): void {
    this.form?.get('imagenes')?.get('logo')?.setValue(url);
    this.logoUrl = url;
  }

  public onUrlIconoChanges(url: string): void {
    this.form?.get('imagenes')?.get('icono')?.setValue(url);
    this.iconoUrl = url;
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.createForm();
    await this.listarClientes();
    if (this.data?._id) {
      this.logoUrl = this.data?.imagenes?.logo;
      this.iconoUrl = this.data?.imagenes?.icono;
    }
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.empresas$?.unsubscribe();
  }
}
