import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { SegmentosRoutingModule } from './segmentos.routing.module';
import { ListadoSegmentosComponent } from './listado-segmentos/listado-segmentos.component';
import { CrearEditarSegmentosComponent } from './crear-editar-segmentos/crear-editar-segmentos.component';

@NgModule({
  declarations: [
    ListadoSegmentosComponent,
    CrearEditarSegmentosComponent
  ],
  imports: [CommonModule, AuxiliaresModule, SegmentosRoutingModule],
})
export class SegmentosModule {}
