import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { EmpresasRoutingModule } from './empresas.routing.module';
import { ListadoEmpresasComponent } from './listado-empresas/listado-empresas.component';
import { CrearEditarEmpresasComponent } from './crear-editar-empresas/crear-editar-empresas.component';

@NgModule({
  declarations: [ListadoEmpresasComponent, CrearEditarEmpresasComponent],
  imports: [CommonModule, AuxiliaresModule, EmpresasRoutingModule],
})
export class EmpresasModule {}
