/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import {
  IListado,
  IUsuario,
  IQueryMongo,
  ICliente,
  ISocketMessage,
  IEmpresa,
  IFamiliaQuimica,
  IProvincia,
  IDepartamento,
  ILocalidad,
  IPrincipioActivo,
  IProducto,
  ISegmento,
  ISubsegmento,
} from 'modelos/src';
import { ClientesService } from '../modulos/clientes/clientes.service';
import { UsuariosService } from '../modulos/usuarios/usuarios.service';
import { WebSocketService } from './websocket';
import { EmpresasService } from '../modulos/empresas/empresas.service';
import { FamiliaQuimicasService } from '../modulos/familiaQuimicas/familiaQuimicas.service';
import { ProvinciasService } from '../modulos/provincias/provincias.service';
import { DepartamentosService } from '../modulos/departamentos/departamentos.service';
import { LocalidadsService } from '../modulos/localidads/localidads.service';
import { PrincipioActivosService } from '../modulos/principioActivos/principioActivos.service';
import { ProductosService } from '../modulos/productos/productos.service';
import { SegmentosService } from '../modulos/segmentos/segmentos.service';
import { SubsegmentosService } from '../modulos/subsegmentos/subsegmentos.service';

type Entidad =
  | 'cliente'
  | 'clientes'
  | 'usuario'
  | 'usuarios'
  | 'empresa'
  | 'empresas'
  | 'familiaQuimica'
  | 'familiaQuimicas'
  | 'producto'
  | 'productos'
  | 'provincia'
  | 'provincias'
  | 'departamento'
  | 'departamentos'
  | 'localidad'
  | 'localidads'
  | 'principioActivo'
  | 'principioActivos'
  | 'segmento'
  | 'segmentos'
  | 'subsegmento'
  | 'subsegmentos';

type Tipo =
  | ICliente
  | IListado<ICliente>
  | IUsuario
  | IListado<IUsuario>
  | IEmpresa
  | IListado<IEmpresa>
  | IFamiliaQuimica
  | IListado<IFamiliaQuimica>
  | IProducto
  | IListado<IProducto>
  | IProvincia
  | IListado<IProvincia>
  | IDepartamento
  | IListado<IDepartamento>
  | ILocalidad
  | IListado<ILocalidad>
  | IPrincipioActivo
  | IListado<IPrincipioActivo>
  | ISegmento
  | IListado<ISegmento>
  | ISubsegmento
  | IListado<ISubsegmento>;

class RequestQueue {
  subscribe: Subject<Tipo>;
  requests: number;
  cache?: Tipo;

  constructor() {
    this.requests = 0;
    this.subscribe = new Subject<Tipo>();
    this.cache = undefined;
  }
}

interface IRequestId {
  fn: (id: string) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IRequestQuery {
  fn: (query: IQueryMongo) => Promise<any>;
  keys: { [key: string]: RequestQueue };
}

interface IEntidades {
  cliente: IRequestId;
  clientes: IRequestQuery;
  usuario: IRequestId;
  usuarios: IRequestQuery;
  empresa: IRequestId;
  empresas: IRequestQuery;
  familiaQuimica: IRequestId;
  familiaQuimicas: IRequestQuery;
  producto: IRequestId;
  productos: IRequestQuery;
  provincia: IRequestId;
  provincias: IRequestQuery;
  departamento: IRequestId;
  departamentos: IRequestQuery;
  localidad: IRequestId;
  localidads: IRequestQuery;
  principioActivo: IRequestId;
  principioActivos: IRequestQuery;
  segmento: IRequestId;
  segmentos: IRequestQuery;
  subsegmento: IRequestId;
  subsegmentos: IRequestQuery;
}

@Injectable({
  providedIn: 'root',
})
export class ListadosService {
  private entidades: IEntidades = this.getInitCache();

  constructor(
    private webSocketService: WebSocketService,
    private clientesService: ClientesService,
    private usuariosService: UsuariosService,
    private empresasService: EmpresasService,
    private familiaQuimicaService: FamiliaQuimicasService,
    private productoService: ProductosService,
    private provinciasService: ProvinciasService,
    private departamentosService: DepartamentosService,
    private localidadsService: LocalidadsService,
    private principioActivoService: PrincipioActivosService,
    private segmentosService: SegmentosService,
    private subsegmentosService: SubsegmentosService,
  ) {
    this.subscribeWsUpdates();
  }

  //

  private log() {
    setInterval(() => {
      for (const ent in this.entidades) {
        const entidad = this.entidades[ent as Entidad];
        for (const key in entidad.keys) {
          if (entidad.keys[key as string].subscribe.observers.length) {
            console.log(
              `Entidad ${ent} key ${key} requests ${
                entidad.keys[key as string].requests
              }. Subscribers ${
                entidad.keys[key as string].subscribe.observers.length
              }`,
            );
          }
        }
      }
    }, 5000);
  }

  // Subscribe

  public subscribe<Tipo>(
    entidad: Entidad,
    query: IQueryMongo | string,
  ): Observable<Tipo> {
    const key = typeof query === 'string' ? query : JSON.stringify(query);
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (!ent.keys[key]) {
        ent.keys[key] = new RequestQueue();
      }
    }
    return ent.keys[key].subscribe.asObservable() as any;
  }

  public async getLastValue(
    entidad: Entidad,
    query: IQueryMongo | string,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!this.entidades[entidad]) {
      throw new Error(`No existe la entidad ${entidad}`);
    } else {
      if (typeof query === 'string') {
        await this.listarId(entidad, query, (ent as IRequestId).fn);
      } else {
        await this.listarQuery(entidad, query, (ent as IRequestQuery).fn);
      }
    }
  }

  // Listados Entidades
  private async listarCliente(id: string): Promise<ICliente> {
    const response = await firstValueFrom(this.clientesService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarClientes(
    query: IQueryMongo,
  ): Promise<IListado<ICliente>> {
    const response = await firstValueFrom(this.clientesService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUsuario(id: string): Promise<IUsuario> {
    const response = await firstValueFrom(this.usuariosService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarUsuarios(
    query: IQueryMongo,
  ): Promise<IListado<IUsuario>> {
    const response = await firstValueFrom(this.usuariosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEmpresa(id: string): Promise<IEmpresa> {
    const response = await firstValueFrom(this.empresasService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarEmpresas(
    query: IQueryMongo,
  ): Promise<IListado<IEmpresa>> {
    const response = await firstValueFrom(this.empresasService.listar(query));

    return JSON.parse(JSON.stringify(response));
  }

  private async listarFamiliaQuimica(id: string): Promise<IFamiliaQuimica> {
    const response = await firstValueFrom(
      this.familiaQuimicaService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarFamiliaQuimicas(
    query: IQueryMongo,
  ): Promise<IListado<IFamiliaQuimica>> {
    const response = await firstValueFrom(
      this.familiaQuimicaService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarProducto(id: string): Promise<IProducto> {
    const response = await firstValueFrom(this.productoService.listarPorId(id));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarProductos(
    query: IQueryMongo,
  ): Promise<IListado<IProducto>> {
    const response = await firstValueFrom(this.productoService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarProvincia(id: string): Promise<IProvincia> {
    const response = await firstValueFrom(
      this.provinciasService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarProvincias(
    query: IQueryMongo,
  ): Promise<IListado<IProvincia>> {
    const response = await firstValueFrom(this.provinciasService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDepartamento(id: string): Promise<IDepartamento> {
    const response = await firstValueFrom(
      this.departamentosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarDepartamentos(
    query: IQueryMongo,
  ): Promise<IListado<IDepartamento>> {
    const response = await firstValueFrom(
      this.departamentosService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLocalidad(id: string): Promise<ILocalidad> {
    const response = await firstValueFrom(
      this.localidadsService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarLocalidads(
    query: IQueryMongo,
  ): Promise<IListado<ILocalidad>> {
    const response = await firstValueFrom(this.localidadsService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarPrincipioActivo(id: string): Promise<IPrincipioActivo> {
    const response = await firstValueFrom(
      this.principioActivoService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarPrincipioActivos(
    query: IQueryMongo,
  ): Promise<IListado<IPrincipioActivo>> {
    const response = await firstValueFrom(
      this.principioActivoService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarSegmento(id: string): Promise<ISegmento> {
    const response = await firstValueFrom(
      this.segmentosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarSegmentos(
    query: IQueryMongo,
  ): Promise<IListado<ISegmento>> {
    const response = await firstValueFrom(this.segmentosService.listar(query));
    return JSON.parse(JSON.stringify(response));
  }

  private async listarSubsegmento(id: string): Promise<ISubsegmento> {
    const response = await firstValueFrom(
      this.subsegmentosService.listarPorId(id),
    );
    return JSON.parse(JSON.stringify(response));
  }

  private async listarSubsegmentos(
    query: IQueryMongo,
  ): Promise<IListado<ISubsegmento>> {
    const response = await firstValueFrom(
      this.subsegmentosService.listar(query),
    );
    return JSON.parse(JSON.stringify(response));
  }

  // Borrar cache
  private getInitCache(): IEntidades {
    return {
      cliente: { fn: this.listarCliente.bind(this), keys: {} },
      clientes: { fn: this.listarClientes.bind(this), keys: {} },
      usuario: { fn: this.listarUsuario.bind(this), keys: {} },
      usuarios: { fn: this.listarUsuarios.bind(this), keys: {} },
      empresa: { fn: this.listarEmpresa.bind(this), keys: {} },
      empresas: { fn: this.listarEmpresas.bind(this), keys: {} },
      familiaQuimica: {
        fn: this.listarFamiliaQuimica.bind(this),
        keys: {},
      },
      familiaQuimicas: {
        fn: this.listarFamiliaQuimicas.bind(this),
        keys: {},
      },
      producto: { fn: this.listarProducto.bind(this), keys: {} },
      productos: { fn: this.listarProductos.bind(this), keys: {} },
      provincia: { fn: this.listarProvincia.bind(this), keys: {} },
      provincias: { fn: this.listarProvincias.bind(this), keys: {} },
      departamento: { fn: this.listarDepartamento.bind(this), keys: {} },
      departamentos: { fn: this.listarDepartamentos.bind(this), keys: {} },
      localidad: { fn: this.listarLocalidad.bind(this), keys: {} },
      localidads: { fn: this.listarLocalidads.bind(this), keys: {} },
      principioActivo: {
        fn: this.listarPrincipioActivo.bind(this),
        keys: {},
      },
      principioActivos: {
        fn: this.listarPrincipioActivos.bind(this),
        keys: {},
      },
      segmento: { fn: this.listarSegmento.bind(this), keys: {} },
      segmentos: { fn: this.listarSegmentos.bind(this), keys: {} },
      subsegmento: { fn: this.listarSubsegmento.bind(this), keys: {} },
      subsegmentos: { fn: this.listarSubsegmentos.bind(this), keys: {} },
    };
  }

  public borrarCache() {
    this.entidades = this.getInitCache();
  }

  // Actualizar Entidades
  private async actualizarQuery(entidad: Entidad): Promise<void> {
    const ent = this.entidades[entidad] as IRequestQuery;
    for (const key in ent.keys) {
      if (ent.keys[key].requests === 0) {
        if (Object.prototype.hasOwnProperty.call(ent.keys, key)) {
          ent.keys[key].cache = undefined;
          const query = JSON.parse(key);
          if (ent.keys[key].subscribe.observers.length) {
            ent.keys[key].requests++;
            while (ent.keys[key].requests) {
              ent.keys[key].cache = undefined;
              await this.listarQuery(entidad, query, ent.fn);
              ent.keys[key].requests--;
            }
          }
        }
      } else if (ent.keys[key].requests < 2) {
        ent.keys[key].requests++;
      }
    }
  }

  private async actualizarId(entidad: Entidad, id?: string): Promise<void> {
    if (id) {
      const ent = this.entidades[entidad] as IRequestId;
      if (ent.keys[id]) {
        if (ent.keys[id].requests === 0) {
          ent.keys[id].cache = undefined;
          if (ent.keys[id].subscribe.observers.length) {
            ent.keys[id].requests++;
            while (ent.keys[id].requests) {
              ent.keys[id].cache = undefined;
              await this.listarId(entidad, id, ent.fn);
              ent.keys[id].requests--;
            }
          }
        } else if (ent.keys[id].requests < 2) {
          ent.keys[id].requests++;
        }
      }
    }
  }

  // Listados Generales

  private async listarQuery(
    entidad: Entidad,
    query: IQueryMongo,
    fn: (query: IQueryMongo) => Promise<any>,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    const key = JSON.stringify(query);
    if (!ent.keys[key].cache) {
      const response = await fn(query);
      ent.keys[key].cache = JSON.parse(JSON.stringify(response));
      ent.keys[key].subscribe.next(response);
    } else {
      ent.keys[key].subscribe.next(ent.keys[key].cache!);
    }
  }

  private async listarId(
    entidad: Entidad,
    id: string,
    fn: (id: string) => Promise<any>,
  ): Promise<void> {
    const ent = this.entidades[entidad];
    if (!ent.keys[id].cache) {
      const response = await fn(id);
      ent.keys[id].cache = JSON.parse(JSON.stringify(response));
      ent.keys[id].subscribe.next(response);
    } else {
      ent.keys[id].subscribe.next(ent.keys[id].cache!);
    }
  }

  // Suscripcion a WS Service para eliminar cache y actualizar entidades
  private subscribeWsUpdates() {
    this.webSocketService.getMessage().subscribe({
      next: this.handleUpdateResponse.bind(this),
    });
  }
  private handleUpdateResponse(message: ISocketMessage) {
    if (message.paths?.includes('clientes')) {
      this.actualizarQuery('clientes');
      this.actualizarId('cliente', message.body?._id);
    }
    if (message.paths?.includes('usuarios')) {
      this.actualizarQuery('usuarios');
      this.actualizarId('usuario', message.body?._id);
    }
    if (message.paths?.includes('empresas')) {
      this.actualizarQuery('empresas');
      this.actualizarId('empresa', message.body?._id);
    }
    if (message.paths?.includes('familiaquimicas')) {
      this.actualizarQuery('familiaQuimicas');
      this.actualizarId('familiaQuimica', message.body?._id);
    }
    if (message.paths?.includes('productos')) {
      this.actualizarQuery('productos');
      this.actualizarId('producto', message.body?._id);
    }
    if (message.paths?.includes('provincias')) {
      this.actualizarQuery('provincias');
      this.actualizarId('provincia', message.body?._id);
    }
    if (message.paths?.includes('departamentos')) {
      this.actualizarQuery('departamentos');
      this.actualizarId('departamento', message.body?._id);
    }
    if (message.paths?.includes('localidads')) {
      this.actualizarQuery('localidads');
      this.actualizarId('localidad', message.body?._id);
    }
    if (message.paths?.includes('principioactivos')) {
      this.actualizarQuery('principioActivos');
      this.actualizarId('principioActivo', message.body?._id);
    }
    if (message.paths?.includes('segmentos')) {
      this.actualizarQuery('segmentos');
      this.actualizarId('segmento', message.body?._id);
    }
    if (message.paths?.includes('subsegmentos')) {
      this.actualizarQuery('subsegmentos');
      this.actualizarId('subsegmento', message.body?._id);
    }
  }
}
