import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ICliente,
  ICreateCliente,
  IListado,
  IQueryMongo,
  IUpdateCliente,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class ClientesService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryMongo): Observable<IListado<ICliente>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/clientes`, { params });
  }

  public crear(dato: ICreateCliente): Observable<ICliente> {
    return this.http.post(`/clientes`, dato);
  }

  public listarPorId(id: string): Observable<ICliente> {
    return this.http.get(`/clientes/${id}`);
  }

  public editar(id: string, dato: IUpdateCliente): Observable<ICliente> {
    return this.http.put(`/clientes/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/clientes/${id}`);
  }
}
