import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { LocalidadsRoutingModule } from './localidads.routing.module';
import { ListadoLocalidadsComponent } from './listado-localidads/listado-localidads.component';
import { CrearEditarLocalidadsComponent } from './crear-editar-localidads/crear-editar-localidads.component';

@NgModule({
  declarations: [ListadoLocalidadsComponent, CrearEditarLocalidadsComponent],
  imports: [CommonModule, AuxiliaresModule, LocalidadsRoutingModule],
})
export class LocalidadsModule {}
