<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      Importar Productos
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0">
    <form
      id="loginForm"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
      style="width: 100%"
    >
      <!-- CSV -->
      <mat-form-field style="width: 100%">
        <!-- <mat-label>CSV</mat-label> -->
        <h3>
          <strong>Formato: </strong> Nro. Registro ; Empresa ; Marca ; Unidad ;
          Segmento ; Subsegmentos ; Principio Activo ; Familia Química ;
          Concentración ; Formulación ; Clase Tox.
        </h3>
        <p>
          <textarea
            #lineCounter
            id="lineCounter"
            wrap="off"
            readonly
            [ngModel]="lineCounterValue"
            [ngModelOptions]="{ standalone: true }"
          >
          </textarea>
          <!-- placeholder="Nro. Registro ; Empresa ; Marca ; Unidad ; Segmento ; Subsegmentos ; Principio Activo ; Familia Química ; Concentración ; Formulación ; Clase Tox." -->
          <textarea
            #textArea
            id="textArea"
            wrap="off"
            matInput
            formControlName="csv"
            required
            (scroll)="onScroll()"
            (input)="onInput()"
          ></textarea>
        </p>

        <!-- <textarea
            matInput
            formControlName="csv"
            placeholder="Nro. Registro ; Empresa ; Marca ; Unidad ; Segmento ; Subsegmentos ; Principio Activo ; Familia Química ; Concentración ; Formulación ; Clase Tox."
            [rows]="numeroDeLineas"
            required
          ></textarea> -->
        <mat-hint>
          Ej. 39418 ; ACA ; 2,4-D ACTION ; Lt ; HERBICIDAS ; ; 2,4-D ETIL HEXIL
          ESTER ; 24D ; 0.89 ; EC ; II
        </mat-hint>
        <mat-error *ngIf="form?.get('csv')?.hasError('error')">
          {{ getErrorMessage() }}
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="loginForm"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button
      mat-raised-button
      type="button"
      form="loginForm"
      fxFlex
      (click)="close()"
    >
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
