<button
  mat-icon-button
  type="button"
  color="primary"
  (click)="fileInput.click()"
  [disabled]="disabled"
>
  <mat-icon color="accent">upload</mat-icon>
</button>

<input
  #fileInput
  type="file"
  style="position: fixed; top: -20000px"
  accept=".jpg, .jpeg, .png"
  (change)="setFile($event)"
/>
