import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IProducto,
  ICreateProducto,
  IListado,
  IQueryMongo,
  IUpdateProducto,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class ProductosService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryMongo): Observable<IListado<IProducto>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/productos`, { params });
  }

  public async exportar(
    queryParams?: IQueryMongo,
    filename = 'productos.xlsx'
  ): Promise<void> {
    let params = HelperService.getQueryParams(queryParams);
    await this.http.getFile(`/productos/exportar`, filename, params);
  }

  public crear(dato: ICreateProducto): Observable<IProducto> {
    return this.http.post(`/productos`, dato);
  }

  public listarPorId(id: string): Observable<IProducto> {
    return this.http.get(`/productos/${id}`);
  }

  public editar(id: string, dato: IUpdateProducto): Observable<IProducto> {
    return this.http.put(`/productos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/productos/${id}`);
  }
}
