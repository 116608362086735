<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    <form
      id="formUsuarios"
      [formGroup]="form!"
      autocomplete="off"
      (ngSubmit)="onSubmit()"
      class="column-center"
    >
      <!-- Cliente -->
      <mat-form-field style="width: 100%">
        <mat-label>Cliente</mat-label>
        <mat-select formControlName="idCliente" required>
          <mat-option *ngFor="let dato of clientes" [value]="dato._id">{{
            dato.nombre
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="row-center" style="gap: 5px">
        <!-- Username  -->
        <mat-form-field style="width: 50%">
          <mat-label>Nombre de usuario</mat-label>
          <input
            matInput
            type="search"
            placeholder="Nombre de usuario"
            formControlName="username"
            required
          />
          <mat-error *ngIf="form?.get('username')?.invalid">{{
            getUsernameErrorMessage()
          }}</mat-error>
        </mat-form-field>

        <!-- Password -->
        <mat-form-field style="width: 50%">
          <mat-label>Password</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'search'"
            [autocomplete]="!this.data ? 'new-password' : 'search'"
            placeholder="Password"
            formControlName="password"
            [required]="this.data ? false : true"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="$event.stopPropagation(); hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- Permisos -->
      <div formArrayName="permisos" class="column-center" style="width: 100%">
        <ng-container
          *ngFor="
            let permisoUnidadNegocio of formArrayPermisos?.controls;
            let i = index;
            let even = even
          "
        >
          <div
            [formGroupName]="i"
            style="
              padding: 0.5em;
              margin-bottom: 1em;
              border: 1px solid #ccc;
              width: 100%;
            "
            [style.background]="even ? 'rgba(0, 0, 0, 0.05)' : 'transparent'"
          >
            <div class="row-center" style="gap: 5px; width: 100%">
              <mat-form-field style="width: 75%">
                <mat-select placeholder="Rol" formControlName="tipo" required>
                  <mat-option *ngFor="let dato of roles" [value]="dato">
                    {{ dato }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-checkbox
                style="display: block; margin-bottom: 1em"
                formControlName="global"
              >
                Global
              </mat-checkbox>
            </div>
            <!--  -->
            <ng-container *ngIf="!permisoUnidadNegocio.get('global')?.value">
              <div class="column-center" style="width: 100%; gap: 5px">
                <!--  -->
                <mat-form-field style="width: 100%">
                  <mat-label>Provincias</mat-label>
                  <mat-select formControlName="idsProvincia" multiple>
                    <mat-option
                      *ngFor="let dato of provincias"
                      [value]="dato._id"
                    >
                      {{ dato.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!--  -->
                <mat-form-field style="width: 100%">
                  <mat-label>Departamentos</mat-label>
                  <mat-select formControlName="idsDepartamento" multiple>
                    <mat-option
                      *ngFor="let dato of departamentos"
                      [value]="dato._id"
                    >
                      {{ dato.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!--  -->
                <mat-form-field style="width: 100%">
                  <mat-label>Localidades</mat-label>
                  <mat-select formControlName="idsLocalidad" multiple>
                    <mat-option
                      *ngFor="let dato of localidades"
                      [value]="dato._id"
                    >
                      {{ dato.nombre }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>

            <!-- Boton Eliminar Permiso -->
            <button
              mat-raised-button
              color="warn"
              matTooltip="Eliminar"
              (click)="eliminarPermiso(i)"
              style="width: 100%"
            >
              Eliminar Permiso
            </button>
          </div>
        </ng-container>
        <!-- Boton Agregar Permiso -->
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="agregarPermiso()"
          style="width: 100%"
        >
          Agregar Permiso
        </button>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em">
    <button
      mat-raised-button
      type="submit"
      form="formUsuarios"
      color="accent"
      fxFlex
      [disabled]="!form?.valid || loading"
    >
      GUARDAR
    </button>
    <button mat-raised-button type="button" fxFlex (click)="close()">
      Volver
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <mat-card-footer>
    <mat-progress-bar
      [mode]="loading ? 'indeterminate' : 'determinate'"
    ></mat-progress-bar>
  </mat-card-footer>
</mat-card>
