import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IProvincia,
  ICreateProvincia,
  IListado,
  IQueryMongo,
  IUpdateProvincia,
} from 'modelos/src';
import { HelperService } from '../../auxiliares/helper.service';
import { HttpService } from '../../auxiliares/http.service';

@Injectable({
  providedIn: 'root',
})
export class ProvinciasService {
  constructor(private http: HttpService) {}

  public listar(queryParams?: IQueryMongo): Observable<IListado<IProvincia>> {
    let params = HelperService.getQueryParams(queryParams);
    return this.http.get(`/provincias`, { params });
  }

  public crear(dato: ICreateProvincia): Observable<IProvincia> {
    return this.http.post(`/provincias`, dato);
  }

  public listarPorId(id: string): Observable<IProvincia> {
    return this.http.get(`/provincias/${id}`);
  }

  public editar(id: string, dato: IUpdateProvincia): Observable<IProvincia> {
    return this.http.put(`/provincias/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/provincias/${id}`);
  }
}
