/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  IComposicion,
  ICreateProducto,
  IEmpresa,
  IFamiliaQuimica,
  IFilter,
  IListado,
  IPrincipioActivo,
  IProducto,
  IQueryMongo,
  ISegmento,
  ISubsegmento,
} from 'modelos/src';
import { Subscription, firstValueFrom } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { HelperService } from '../../../auxiliares/helper.service';
import { ListadosService } from '../../../auxiliares/listados.service';
import { CrearEditarEmpresasComponent } from '../../empresas/crear-editar-empresas/crear-editar-empresas.component';
import { CrearEditarFamiliaQuimicasComponent } from '../../familiaQuimicas/crear-editar-familia-quimicas/crear-editar-familia-quimicas.component';
import { CrearEditarPrincipioActivosComponent } from '../../principioActivos/crear-editar-principio-activos/crear-editar-principio-activos.component';
import { CrearEditarSegmentosComponent } from '../../segmentos/crear-editar-segmentos/crear-editar-segmentos.component';
import { CrearEditarSubsegmentosComponent } from '../../subsegmentos/crear-editar-subsegmentos/crear-editar-subsegmentos.component';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-crear-editar-productos',
  templateUrl: './crear-editar-productos.component.html',
  styleUrls: ['./crear-editar-productos.component.scss'],
})
export class CrearEditarProductosComponent implements OnInit, OnDestroy {
  public loading = false;
  public form?: UntypedFormGroup;
  // public title?: string;

  public cantidadCompuestos = 0;
  public composiciones: IComposicion[] = [];

  public data?: IProducto;

  public unidades: string[] = ['Lt', 'Kg', 'Pack', 'Otro'];
  public formulaciones: string[] = [
    'CS',
    'EC',
    'EW',
    'FS',
    'GR',
    'ME',
    'MR',
    'OD',
    'SC',
    'SE',
    'SG',
    'SL',
    'SP',
    'WG',
    'WP',
  ];
  public clasesTox: string[] = ['N/A', 'I', 'Ia', 'Ib', 'II', 'III', 'IV'];

  public empresas: IEmpresa[] = [];
  public segmentos: ISegmento[] = [];
  public subsegmentos: ISubsegmento[] = [];
  public familiaQuimicas: IFamiliaQuimica[] = [];
  public principioActivos: IPrincipioActivo[] = [];

  // Listado Continuo
  public data$?: Subscription;
  public empresas$?: Subscription;
  public segmentos$?: Subscription;
  public subsegmentos$?: Subscription;
  public familiaQuimicas$?: Subscription;
  public principioActivos$?: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    public matDialog: MatDialog,
    private helper: HelperService,
    private service: ProductosService,
    private listadosService: ListadosService,
  ) {}

  private createForm(): void {
    // this.title = this.data?._id ? 'Editar Productos' : 'Crear Productos';
    this.form = this.fb.group({
      nombre: [this.data?.nombre, Validators.required],
      idEmpresa: [this.data?.idEmpresa],
      idSegmento: [this.data?.idSegmento],
      idsSubsegmentos: [this.data?.idsSubsegmentos],
      sku: [this.data?.sku],
      numeroRegistro: [this.data?.numeroRegistro],
      tipo: [this.data?.tipo],
      unidad: [this.data?.unidad],
      formulacion: [this.data?.formulacion],
      claseTox: [this.data?.claseTox],
      dosisMedia: [this.data?.dosisMedia],
    });
  }

  public volver() {
    window.history.back();
  }

  public botonEnviarDisabled(): boolean {
    return !this.form?.valid || !this.composicionesValidas() || this.loading;
  }

  private getData() {
    const data: ICreateProducto = this.form?.value;
    data.composicion = this.composiciones;
    return data;
  }
  public async onSubmit(): Promise<void> {
    this.loading = true;
    try {
      const data = this.getData();
      if (this.data?._id) {
        await this.service
          .editar(this.data._id, data)
          .pipe(first())
          .toPromise();
        this.helper.notifSuccess('Editado correctamente');
      } else {
        await this.service.crear(data).pipe(first()).toPromise();
        this.helper.notifSuccess('Creado correctamente');
      }
      this.volver();
    } catch (err) {
      console.error(err);
      this.helper.notifError(err);
    }
    this.loading = false;
  }

  // NgSelect
  public searchPrincipioActivo = (term: string, item: IPrincipioActivo) => {
    if (item.nombre.toLowerCase().includes(term.toLowerCase())) return true;
    if (item.familiaQuimica?.nombre.toLowerCase().includes(term.toLowerCase()))
      return true;
    return false;
  };

  // Composicion

  public cambioCantidadCompuestos() {
    if (this.composiciones.length < this.cantidadCompuestos) {
      for (
        let i = this.composiciones.length;
        i < this.cantidadCompuestos;
        i++
      ) {
        this.composiciones.push({
          formulacion:
            this.composiciones[this.composiciones.length - 1]?.formulacion,
          claseTox: this.composiciones[this.composiciones.length - 1]?.claseTox,
          principal: this.cantidadCompuestos === 1,
        });
      }
    } else {
      for (
        let i = this.composiciones.length;
        i >= this.cantidadCompuestos;
        i--
      ) {
        this.composiciones.splice(i, 1);
      }
    }
  }
  public getCompuesto() {
    let str = '';
    let i = 0;
    for (const comp of this.composiciones) {
      if (comp.idPrincipioActivo) {
        const primero = i === 0 ? true : false;
        const principioActivo = this.principioActivos.find(
          (p) => p._id === comp.idPrincipioActivo,
        );
        const nombre = `${
          principioActivo?.nombreCorto || principioActivo?.nombre
        } ${comp.concentracion || 0}%`;
        str += primero ? nombre : ' + ' + nombre;
      }
      i++;
    }
    return str;
  }
  public composicionValida(composicion: IComposicion): boolean {
    return !!(
      composicion.concentracion &&
      composicion.idPrincipioActivo &&
      composicion.idFamiliaQuimica
    );
  }
  public composicionesValidas(): boolean {
    for (const comp of this.composiciones) {
      if (!this.composicionValida(comp)) {
        return false;
      }
    }
    return true;
  }

  // Crear
  public async crearEmpresa(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      disableClose: true,
    };
    const modal = this.matDialog.open<
      CrearEditarEmpresasComponent,
      any,
      IEmpresa
    >(CrearEditarEmpresasComponent, config);
    const creado = await modal.afterClosed().pipe(take(1)).toPromise();
    if (creado) {
      this.form?.patchValue({ idEmpresa: creado._id });
    }
  }
  public async crearSegmento(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      disableClose: true,
    };
    const modal = this.matDialog.open<
      CrearEditarSegmentosComponent,
      any,
      ISegmento
    >(CrearEditarSegmentosComponent, config);
    const creado = await modal.afterClosed().pipe(take(1)).toPromise();
    if (creado) {
      this.form?.patchValue({ idSegmento: creado._id });
    }
  }
  public async crearSubsegmento(): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      disableClose: true,
    };
    const modal = this.matDialog.open<
      CrearEditarSubsegmentosComponent,
      any,
      ISubsegmento
    >(CrearEditarSubsegmentosComponent, config);
    const creado = await modal.afterClosed().pipe(take(1)).toPromise();
    if (creado) {
      const idSegmento = this.form?.get('idSegmento')?.value;
      if (idSegmento && idSegmento === creado.idSegmento) {
        const idsSubsegmentos: string[] =
          this.form?.get('idsSubsegmentos')?.value || [];
        idsSubsegmentos.push(creado._id);
        this.form?.patchValue({ idsSubsegmentos: idsSubsegmentos });
      } else {
        this.form?.patchValue({ idSegmento: creado.idSegmento });
        this.form?.patchValue({ idsSubsegmentos: [creado._id] });
      }
    }
  }
  public async crearFamiliaQuimica(index: number): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      disableClose: true,
    };
    const modal = this.matDialog.open<
      CrearEditarFamiliaQuimicasComponent,
      any,
      IFamiliaQuimica
    >(CrearEditarFamiliaQuimicasComponent, config);
    const creado = await modal.afterClosed().pipe(take(1)).toPromise();
    if (creado) {
      this.composiciones[index].idFamiliaQuimica = creado._id;
    }
  }
  public async crearPrincipioActivo(index: number): Promise<void> {
    const config: MatDialogConfig = {
      width: '700px',
      maxWidth: '90%',
      panelClass: 'dialog-no-padding',
      disableClose: true,
    };
    const modal = this.matDialog.open<
      CrearEditarPrincipioActivosComponent,
      any,
      IPrincipioActivo
    >(CrearEditarPrincipioActivosComponent, config);
    const creado = await modal.afterClosed().pipe(take(1)).toPromise();
    if (creado) {
      this.composiciones[index].idPrincipioActivo = creado._id;
      this.composiciones[index].idFamiliaQuimica = creado.idFamiliaQuimica;
    }
  }

  // onChange
  public cambioFormulacion() {
    this.composiciones.forEach((c) => {
      if (!c.formulacion) c.formulacion = this.form?.get('formulacion')?.value;
    });
  }
  public cambioClaseTox() {
    this.composiciones.forEach((c) => {
      if (!c.claseTox) c.claseTox = this.form?.get('toxicidad')?.value;
    });
  }
  public async cambioSegmento(): Promise<void> {
    this.form?.patchValue({ idSubsegmento: undefined });
    await this.listarSubsegmentos();
  }
  public cambioPrincipioActivo(
    principioActivo: IPrincipioActivo | undefined,
    i: number,
  ) {
    this.composiciones[i].idFamiliaQuimica = principioActivo?.idFamiliaQuimica;
  }

  // Listados
  private async listarProducto(id: string | null) {
    if (id) {
      this.data$?.unsubscribe();
      this.data$ = this.listadosService
        .subscribe<IProducto>('producto', id)
        .subscribe((data) => {
          this.data = data;
          if (this.data?.composicion) {
            this.cantidadCompuestos = this.data.composicion?.length;
            this.composiciones = JSON.parse(
              JSON.stringify(this.data.composicion),
            );
          }
          console.log(`producto`, data);
        });
      await this.listadosService.getLastValue('producto', id);
    }
  }

  private async listarEmpresas(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.empresas$?.unsubscribe();
    this.empresas$ = this.listadosService
      .subscribe<IListado<IEmpresa>>('empresas', query)
      .subscribe((data) => {
        this.empresas = data.datos;
        console.log(`listado de empresas`, data);
      });
    await this.listadosService.getLastValue('empresas', query);
  }
  private async listarSegmentos(): Promise<void> {
    const query: IQueryMongo = {
      select: 'nombre',
      sort: 'nombre',
    };
    this.segmentos$?.unsubscribe();
    this.segmentos$ = this.listadosService
      .subscribe<IListado<ISegmento>>('segmentos', query)
      .subscribe((data) => {
        this.segmentos = data.datos;
        console.log(`listado de segmentos`, data);
      });
    await this.listadosService.getLastValue('segmentos', query);
  }
  private async listarSubsegmentos(): Promise<void> {
    const idSegmento = this.form?.value.idSegmento;
    if (idSegmento) {
      const filter: IFilter[] = [
        {
          field: 'idSegmento',
          type: 'objectid',
          value: idSegmento,
        },
      ];
      const query: IQueryMongo = {
        select: 'nombre idSegmento',
        sort: 'nombre',
        filter: JSON.stringify(filter),
      };
      this.subsegmentos$?.unsubscribe();
      this.subsegmentos$ = this.listadosService
        .subscribe<IListado<ISubsegmento>>('subsegmentos', query)
        .subscribe((data) => {
          this.subsegmentos = data.datos;
          console.log(`listado de subsegmentos`, data);
        });
      await this.listadosService.getLastValue('subsegmentos', query);
    }
  }
  private async listarPrincipioActivos(): Promise<void> {
    const populate = {
      path: 'familiaQuimica',
      select: 'nombre',
    };
    const query: IQueryMongo = {
      select: 'nombre idFamiliaQuimica',
      sort: 'nombre',
      populate: JSON.stringify(populate),
    };
    this.principioActivos$?.unsubscribe();
    this.principioActivos$ = this.listadosService
      .subscribe<IListado<IPrincipioActivo>>('principioActivos', query)
      .subscribe((data) => {
        this.principioActivos = data.datos;
        console.log(`listado de principioActivos`, data);
      });
    await this.listadosService.getLastValue('principioActivos', query);
  }

  //

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const params = await firstValueFrom(this.route.paramMap);
    const id = params.get('id');
    await this.listarProducto(id);
    this.createForm();
    await Promise.all([
      this.listarEmpresas(),
      this.listarSegmentos(),
      this.listarSubsegmentos(),
      this.listarPrincipioActivos(),
    ]);
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.data$?.unsubscribe();
    this.empresas$?.unsubscribe();
    this.segmentos$?.unsubscribe();
    this.subsegmentos$?.unsubscribe();
    this.familiaQuimicas$?.unsubscribe();
    this.principioActivos$?.unsubscribe();
  }
}
